import React, { useEffect, useState } from 'react';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import Logo from '@assets/svg/CloudClevr.svg';
import LogoCrop from '@assets/svg/logo_crop.svg';
import LogoElement from '@assets/svg/logo_element.svg';
import { DashboardTiles } from '@common/constants';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import {
  formatNumberWithSuffix,
  hasNestedArray,
  formatDateInUTC,
} from '@utils/index';
import {
  LineChartIcon,
  LineChartIconUp,
  CalendarIcon,
  ArrowLongRightIcon,
} from '@components/ui/Icons';
import { KeyRecommendations } from '@pages/Reports/PdfReports/KeyRecommendations';
import { convertReportTitle } from '@pages/Reports/PdfReports/ReportTitleConvertor';
import styles from './styles.module.css';
import { MsSecureScore1 } from '@pages/Reports/PdfReports/SecureScore/Page1/MsSecureScore1';
import { MsSecureScore2 } from '@pages/Reports/PdfReports/SecureScore/Page2/MsSecureScore2';

interface DecodedData {
  title: string;
  tenantName: string;
  fromDate: string;
  toDate: string;
  subReports: string[];
  pages: {
    title: string;
    components: any[];
  }[];
}

const SecurityReportPdf: React.FC = () => {
  const [decodedData, setDecodedData] = useState<DecodedData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isDataProcessed, setIsDataProcessed] = useState(false);

  useEffect(() => {
    const handleData = async (event: MessageEvent) => {
      try {
        if (event.data && event.data.type === 'PDF_DATA') {
          console.log('Received PDF data:', event.data.payload);
          
          setDecodedData(event.data.payload);
          setIsDataProcessed(true);
          
          window.postMessage({
            type: 'PDF_DATA_PROCESSED',
            success: true
          }, '*');
          
          (window as any).__PDF_DATA_READY = true;
        }
      } catch (err) {
        // Type guard for Error object
        const error = err as Error;
        console.error('Failed to process data:', error);
        setError(error.message || 'An unknown error occurred');
        
        window.postMessage({
          type: 'PDF_DATA_PROCESSED',
          success: false,
          error: error.message || 'An unknown error occurred'
        }, '*');
      }
    };

    (window as any).__PDF_DATA_READY = false;
    window.addEventListener('message', handleData);

    return () => {
      window.removeEventListener('message', handleData);
    };
  }, []);
  // Add loading state handler
  useEffect(() => {
    if (isDataProcessed) {
      console.log('Data processed successfully');
    }
  }, [isDataProcessed]);

  if (error) {
    return (
      <div className={styles.error}>
        <h2>Error Loading Data</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!decodedData || !isDataProcessed) {
    return (
      <div className={styles.loading}>
        <h2>Loading...</h2>
        <p>Please wait while the data is being processed...</p>
      </div>
    );
  }

  const currentDate = new Date().toLocaleDateString();

  const renderPageContent = (item: any, indexPage: number) => {
    const pageTitle = item.title;//convertReportTitle(item.title);
    const pageTitleConverted = convertReportTitle(item.title);
    switch (pageTitle) {
      case 'Key recommendations':
        return <KeyRecommendations components={item.components} />;
      case 'MS SecureScore 1':
        return <MsSecureScore1 data={item.components} />;
       case 'MS SecureScore 2':        
           return <MsSecureScore2 data={item.components} />;        
      default:
        return (
          <div className={styles.components}>
            <pre className="overflow-auto max-h-64 p-4 bg-gray-50 rounded border">
              {JSON.stringify(item.components, null, 2)}
            </pre>
          </div>
        );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <img
          className={styles.logo}
          src={Logo}
          alt="Clevr360 logo"
        />

        <h1 className={styles.title}>
          {decodedData?.title || 'Security Assurance Report (Default)'}
        </h1>
        <h3 className={styles.tenantName}>
          {decodedData?.tenantName || ''}
        </h3>

        <h3 className={styles.date}>
          <CalendarIcon
            color="text-interfaceColor-100"
            size="8"
            classNames="mx-1"
          />
          {formatDateInUTC(decodedData?.fromDate)}
          <ArrowLongRightIcon color="text-interfaceColor-100" size="5" />
          {formatDateInUTC(decodedData?.toDate)}
        </h3>

        <div className={styles.subReports}>
          {decodedData.subReports &&
            decodedData.subReports.map((item: any) => {
              return (
                <p
                  key={item}
                  className={styles.subReportItem}
                >
                  - {convertReportTitle(item)}
                </p>
              );
            })}
        </div>
        <img
          className={styles.logoCrop}
          src={LogoCrop}
          alt="CloudClevr LogoCrop"
        />
      </div>
      {decodedData.pages.map((item: any, indexPage: number) => (
        <div
          key={item.title}
          className={styles.page}
        >
          <h1 className={styles.pageTitle}>
            {convertReportTitle(item.title)}
          </h1>
          <div>
             {renderPageContent(item, indexPage)}
          </div>          
          <div className={styles.footer}>
            <img
              src={LogoElement}
              alt="logo inverted"
              className={styles.footerLogo}
            />
            <span>
              {decodedData?.title}
            </span>
          </div>
          <p className={styles.pageNumber}>
            {indexPage + 1}
          </p>
        </div>
      ))}
    </>
  );
};

export default SecurityReportPdf;