import {
  DEFAULT_PAGE_SIZE,
  DashboardTiles,
  LICENSES_SORT_BY,
  LICENSES_TABLE_COLUMNS,
  VENDORS,
} from '@common/constants';
import { ExtendedOption, IOption, OrderBy } from '@common/types';
import { TableWrapper } from '@components/partials';
import { OverlayPage } from '@components/partials/OverlayPage';
import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { ArrowRightIcon, DashboardIcon, LightInfoIcon } from '@components/ui';
import { MenuItem } from '@components/ui/DropdownMenu';
import {
  useLicencesFiltersQuery,
  useLicencesOverassignedQuery,
  useLicencesOverassignedTrendQuery,
  useLicensesOverassignedPreviewQuery,
  useRedundantLicencesFiltersQuery,
} from '@hooks/licenses';
import { useDashboardTileSaveMutation } from '@hooks/dashboard';
import {
  LicensesFilterData,
  LicensesFiltersResponse,
} from '@hooks/licenses/types';
import { PeopleSortBy } from '@hooks/people/types';
import { useIsDesktop } from '@hooks/utils';
import {
  LineChartTile,
  LineChartTypes,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import {
  formatDate,
  formatLicensesFilter,
  getTrendData,
  getTrendLabels,
  useTableData,
} from '@utils/index';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles//NumberTile';
import styles from './styles.module.css';
import { useConnectedServicesQuery } from '@hooks/connectedServices';
import { AvailableServices } from '@pages/HubSettings/ConnectedServices';
import { createCellValue } from './utils';
import { useNavSourceRedirect } from '@hooks/utils/dashboard';
import { useLocationsQuery } from '@hooks/locations';
import { useGroupsFilterQuery } from '@hooks/groups';
import { Location } from '@hooks/locations/types';
import { GroupsResponse } from '@hooks/groups/types';
import useDownloadCsv from '@hooks/utils/export';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import RecommendationsBox from './RecommendationsBox';

const pageSize = DEFAULT_PAGE_SIZE;

const OverassignedLicensePage: React.FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState('');
  const isDesktop = useIsDesktop();
  const redirectPath = useNavSourceRedirect(
    '/insights-and-analytics/utilisation'
  );
  const [vendorUnassignment, setVendorUnassignment] = useState('All');
  const [days, setDays] = useState('30');
  const [redundantLicenseReason, setRedundantLicenseReason] = useState('All');
  const { data: licenseInsightFilter, isFetched: isLicencesesInsightFilterFetched } = useRedundantLicencesFiltersQuery();

  const sortMenuItems: MenuItem[] = [
    {
      id: '1',
      label: 'Last 30 days',
      value: '30',
      action: () => handleDropdown('30'),
    },
    {
      id: '2',
      label: 'Last 60 days',
      value: '60',
      action: () => handleDropdown('60'),
    },
    {
      id: '3',
      label: 'Last 90 days',
      value: '90',
      action: () => handleDropdown('90'),
    },
  ];

  const handleDropdown = (value: string) => {
    setDays(value);
  };

  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [queryParams, setQueryParams] = useQueryParams({
    search: withDefault(StringParam, ''),
    sortBy: withDefault(StringParam, 'assignedto'),
    order: withDefault(StringParam, 'asc'),
    filter: withDefault(StringParam, ''),
  });

  const {
    data: licencesOverassignedData,
    isLoading: isLicencesOverassignedLoading,
  } = useLicencesOverassignedQuery({
    includeFreeLicenses: queryParams?.filter?.includes('freeLicenses'),
    vendorList:
      vendorUnassignment === 'All'
        ? licenseInsightFilter?.vendorList
        : [vendorUnassignment],
    reasonList: redundantLicenseReason === 'All'
      ? licenseInsightFilter?.reasonList
      : [redundantLicenseReason],

  });

  const { data: licencesTrendData, isLoading: isLicencesUtilisationLoading } =
    useLicencesOverassignedTrendQuery({
      days,
      includeFreeLicenses: queryParams?.filter?.includes('freeLicenses'),
      vendorList: queryParams.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
        : [],
      licenseTypeList: formatLicensesFilter(queryParams.filter),
    });

  const labels = getTrendLabels(licencesTrendData?.trend || [], +days);

  const trendData = getTrendData(licencesTrendData?.trend || [], +days);

  const { data: connectedServicesData } = useConnectedServicesQuery();

  const query = useLicensesOverassignedPreviewQuery({
    pageSize,
    pageNumber,
    searchTerm: search,
    sortBy: queryParams.sortBy as PeopleSortBy,
    order: queryParams.order as OrderBy,
    locationList: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => locationIds.includes(filter))
      : [],
    groupList: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => groupIds.includes(filter))
      : [],
    vendorList: queryParams.filter
      ? queryParams.filter
        .split(',')
        .filter((filter) => VENDORS.includes(filter as any))
      : [],

    includeFreeLicenses: queryParams?.filter?.includes('freeLicenses'),
    licenseTypeList: formatLicensesFilter(
      queryParams.filter,
      locationIds,
      groupIds
    ),
  });
  const getTableData = useTableData(
    query,
    LICENSES_TABLE_COLUMNS,
    createCellValue
  );

  const { data: filterData, isFetched: filterDataFetched } =
    useLicencesFiltersQuery(
      'true',
      queryParams?.filter?.includes('freeLicenses')
    );
  const { data: locationsData, isFetched: locationDataFetched } =
    useLocationsQuery();
  const { data: groupsData, isFetched: groupsDataFetched } =
    useGroupsFilterQuery();

  const propertyLabels: Record<string, string> = {
    vendor: 'Vendor',
    licensetype: 'License Type',
  };

  const getPropertyLabel = (property: string): string => {
    return propertyLabels[property.toLowerCase()] || property;
  };

  const allLocationIds: string[] = [];
  const groupsIDs: string[] = [];
  const locationFilerOptions: IOption[] = [];
  const groupFilerOptions: ExtendedOption[] = [];
  const transformedFilterData: FilterData[] = [];

  if (isLicencesesInsightFilterFetched) {
    const filterDataArray: FilterData = {
      label: 'Vendor',
      name: 'vendor',
      options: licenseInsightFilter?.vendorList.map(vendor => ({ label: vendor, value: vendor })) || [],
      singleSelect: false,
    };
    transformedFilterData.push(filterDataArray);
  }

  if (filterDataFetched && locationDataFetched && groupsDataFetched) {
    if (filterData) {
      Object.keys(filterData).forEach((property) => {
        if (property === 'vendor') return;
        const filterDataArray: FilterData = {
          label: getPropertyLabel(property),
          name: property.toLowerCase(),
          options: [],
          singleSelect: false,
        };

        const propertyData =
          filterData[property as keyof LicensesFiltersResponse];

        if ('value' in propertyData) {
          const item = propertyData as LicensesFilterData;

          const option = {
            label: item.value || item.vendor,
            value: item.value || item.vendor,
            count: item.count,
          };

          filterDataArray.options.push(option);
        } else {
          const items = propertyData as unknown as LicensesFilterData[];

          items.forEach((item) => {
            const option = {
              label: item.value || item.vendor,
              value: item.value || item.vendor,
            };

            filterDataArray.options.push(option);
          });
        }

        filterDataArray.options.forEach((option) => {
          const matchingVendor = filterData.licenseType.find(
            (v) => v.value === option.value
          );

          if (matchingVendor) {
            option.vendor = matchingVendor.vendor;
          }
        });

        transformedFilterData.push(filterDataArray);
      });

      transformedFilterData.push({
        label: 'Free licences',
        name: 'freeLicenses',
        singleSelect: true,
        options: [{ label: 'Show free licenses', value: 'freeLicenses' }],
      });
    }

    if (locationsData) {
      locationsData?.forEach((location: Location) => {
        locationFilerOptions.push({ label: location.name, value: location.id });
        allLocationIds.push(location.id);
      });

      transformedFilterData.push({
        label: 'Location',
        name: 'location',
        singleSelect: false,
        options: locationFilerOptions,
      });
    }

    if (groupsData) {
      groupsData?.forEach((group: GroupsResponse) => {
        groupFilerOptions.push({
          name: group.name,
          value: group.id,
          parentId: group.parentId,
          subGroups: group.subGroups,
          id: group.id,
          label: group.name,
        });
      });

      transformedFilterData.push({
        label: 'Groups',
        name: 'groups',
        singleSelect: false,
        options: groupFilerOptions,
      });
    }
  }

  useEffect(() => {
    locationsData?.forEach((location: Location) => {
      locationFilerOptions.push({ label: location.name, value: location.id });
      allLocationIds.push(location.id);
    });
    setLocationIds(allLocationIds);
  }, [locationsData]);

  useEffect(() => {
    const nested = (element: any) => {
      element.subGroups.forEach((subElement: any) => {
        groupsIDs.push(subElement.id);
        if (subElement.subGroups && subElement.subGroups.length > 0) {
          nested(subElement);
        }
      });
    };

    groupsData?.forEach((element: any) => {
      groupsIDs.push(element.id);
      if (element.subGroups && element.subGroups.length > 0) {
        nested(element);
      }
    });

    setGroupIds(groupsIDs);
  }, [groupsData]);

  const handleLicenseUnassignmentDropdown = (value: string) => {
    setVendorUnassignment(value);
  };

  const dashboardSaveMutation = useDashboardTileSaveMutation();

  const handleItemAction = (type: DashboardTiles) => () => {
    const getParametersData = () => {
      switch (type) {
        case DashboardTiles.OverassignedLicensesTrendOverTime:
          return {
            days,
            vendorList: queryParams.filter
              ? queryParams.filter
                .split(',')
                .filter((filter) => VENDORS.includes(filter as any))
              : [],
            licenseTypeList: formatLicensesFilter(queryParams.filter),
          };
        case DashboardTiles.OverassignedLicensesSnapshot:
          return {
            vendorList:
              vendorUnassignment === 'All'
                ? licenseInsightFilter?.vendorList
                : [vendorUnassignment],
            reasonList: redundantLicenseReason === 'All'
              ? licenseInsightFilter?.reasonList
              : [redundantLicenseReason],
          };
        default:
          return {};
      }
    };
    const parametersData = getParametersData();

    dashboardSaveMutation.mutate({
      parameters: JSON.stringify(parametersData),
      tileType: type,
    });
  };

  const createMenuItems = (tileType: DashboardTiles): MenuItem[] => [
    {
      id: '0',
      label: 'Add to Dashboard',
      icon: <DashboardIcon classNames="mr-1" />,
      value: 'addToDashboard',
      action: handleItemAction(tileType),
    },
  ];

  const createSortVendorsMenuItems = (
  ) => {
    const Menu = [{
      id: '1',
      label: 'All Vendors',
      action: () => handleLicenseUnassignmentDropdown('All'),
    }
    ];
    const reasons = new Set<string>(licenseInsightFilter?.vendorList.map((item: string) => item));
    Array.from(reasons).map((item: string) => {
      Menu.push({
        id: item,
        label: item,
        action: () => handleLicenseUnassignmentDropdown(item),
      });
    });
    return Menu;
  }

  const createCompareLicenseMenuItems = (
  ) => {
    const Menu = [
      {
        id: '1',
        label: 'All reasons',
        action: () => setRedundantLicenseReason('All'),
      },
    ];
    const reasons = new Set<string>(licenseInsightFilter?.reasonList.map((item: string) => item));
    Array.from(reasons).map((item: string) => {
      Menu.push({
        id: item,
        label: item,
        action: () => setRedundantLicenseReason(item),
      });
    });
    return Menu;
  }


  const selectedSortItem = sortMenuItems.find((item) => item.value === days);

  const legendLabels = [(selectedSortItem && selectedSortItem.label) || ''];

  const [shouldDownload, setShouldDownload] = useState(false);

  const data = useDownloadCsv({
    shouldDownload,
    endpoint: `${API_ENDPOINTS.LICENSES_OVERASSIGNED}/export`,
    params: {
      pageSize,
      pageNumber,
      searchTerm: search,
      sortBy: queryParams.sortBy as PeopleSortBy,
      order: queryParams.order as OrderBy,
      locationList: queryParams.filter
        ? queryParams?.filter
          .split(',')
          .filter((filter) => locationIds.includes(filter))
        : [],
      groupList: queryParams?.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => groupIds.includes(filter))
        : [],
      vendorList: queryParams?.filter
        ? queryParams.filter
          .split(',')
          .filter((filter) => VENDORS.includes(filter as any))
        : [],

      includeFreeLicenses: queryParams?.filter?.includes('freeLicenses'),
      licenseTypeList: formatLicensesFilter(
        queryParams?.filter,
        locationIds,
        groupIds
      ),
    },
    filename: `Clevr360_Overassigned_licenses_${formatDate(
      new Date().toString()
    )}.csv`,
  });

  const downloadCsv = data?.refetch;

  const handleDownloadClick = () => {
    setShouldDownload(true);
    downloadCsv && downloadCsv();
    setShouldDownload(false);
  };

  return (
    <OverlayPage
      isFooterVisible={false}
      contentClassNames={''}
      path={redirectPath}
      headerTitle={`Redundant licenses`}
    >
      <div className={styles.pageGrid}>
        {isDesktop && (
          <>
            <LineChartTile
              classNames={styles.lineChartTile}
              sortData={sortMenuItems}
              compareData={undefined}
              compareLabel=""
              initialSelectedItem={selectedSortItem}
              legendLabels={legendLabels}
              dataSetColors={['#00CF6C']}
              contextData={createMenuItems(
                DashboardTiles.OverassignedLicensesTrendOverTime
              )}
              tooltipLabel={'redundant licenses'}
              headerTitle={'Redundant licenses: Trend over time'}
              isLoading={isLicencesUtilisationLoading}
              labels={labels}
              data={[trendData]}
              dataSetTypes={[LineChartTypes.Dots]}
            />

            <NumberTile
              sortData={createSortVendorsMenuItems()}
              compareData={createCompareLicenseMenuItems()}
              contextData={createMenuItems(
                DashboardTiles.OverassignedLicensesSnapshot
              )}
              headerTitle={'Redundant licenses'}
              isLoading={isLicencesOverassignedLoading}
              percentageLabel=""
              data={
                licencesOverassignedData
                  ? Object.values(licencesOverassignedData)
                  : []
              }
              buttonLabel=""
            />
          </>
        )}

        <RecommendationsBox connectedServicesData={connectedServicesData} />
      </div>

      <TableWrapper
        search={search}
        setSearch={setSearch}
        searchPlaceholder={'Search by license type, name, email address'}
        columns={LICENSES_TABLE_COLUMNS}
        data={getTableData()}
        sortData={LICENSES_SORT_BY}
        filterData={transformedFilterData}
        searchKey="search"
        query={query}
        refetchQuery={query.refetch}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        setPageNumber={setPageNumber}
        isCollapsable={true}
        sliceColumns={2}
        floatingFilterButton={true}
        hasFilterBanner={false}
        isLicensePage={true}
        searchCountStatsLabel="redundant licenses"
        filterHeaderText="Filter redundant licenses"
        isDownloadAvailable={true}
        handleDownload={handleDownloadClick}
        noResultTitle={
          search
            ? 'No results matching your search'
            : `You don't have any redundant licenses`
        }
        noResultSubtitle={search ? 'Try a less specific search.' : ''}
        noResultAction={
          <div className="mt-5 w-full">
            <Link
              to={'/insights-and-analytics/utilisation/license-inventory/subscriptions'}
              className={styles.noResultButton}
            >
              View your license inventory
              <ArrowRightIcon
                color={'text-primaryBrandColor-100'}
                size="3"
                classNames="ml-2 mt-[2px]"
              />
            </Link>
          </div>
        }
      />
    </OverlayPage>
  );
};

export default OverassignedLicensePage;
