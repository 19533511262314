import { Button, OnwardIcon } from '@components/ui';
import { useNavigate } from 'react-router-dom';

type NavigateButtonProps = {
  actionId: string;
};

const NavigateButton: React.FC<NavigateButtonProps> = ({ actionId }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(
      `/insights-and-analytics/assurance/microsoft-secure-score/action/${actionId}`
    );
  };

  return (
    <Button
      onClick={handleClick}
      size="extraSmall"
      variant="outlineLight"
      className={
        'border-none shadow-none hover:bg-transparent focus:shadow-none'
      }
    >
      <OnwardIcon classNames="cursor-pointer" size="5" />
    </Button>
  );
};

export const createCellValue = (property: string, data: any, index: number, isMobile?: boolean,
  formatedValue?: string, hideItem?: boolean) => {
  const value = data[index][property];
  switch (property) {
    case 'title':
        return (
          <div className="flex flex-row items-center">
            <p>{value}</p>
            {!hideItem && <NavigateButton actionId={data[index].id} />}
          </div>
        );
    case 'scoreImpact':
      return (
        <>
          <span>{`+${value?.toFixed(1)}%`}</span>
        </>
      );
    case 'isCompleted':
      return (
        <>
          <span
            className={`status -mt-[3px] align-middle status-${value ? 'active' : 'address'
              }`}
          ></span>
          <span>{value ? 'Completed' : 'To address'}</span>
        </>
      );
  }
};
