import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
} from 'react-query';
import {
  ReportFiltersResponse,
  ReportPreviewResponse,
  ReportResponse,
  SecurityV2ReportPreviewResponse,
  UseReportsPreviewQueryOptions,
  UseReportsQueryOptions,
  SecurityReportKeyRecommendation,
  SecurityReportTabs,
  securityReportSeverityOrder,
} from './types';
import { API_ENDPOINTS } from '@api/ApiEndpoints';
import { useHttpClient } from '@hooks/utils/httpConfig';

export function useReportQuery({
  searchTerm,
  sortBy,
  order,
  status,
  pageSize = 20,
  pageNumber = 1,
  groupTypeList,
  startDate,
  endDate,
}: UseReportsQueryOptions = {}): UseQueryResult<ReportResponse> {
  const reportClient = useHttpClient();
  const fetchReports = async (): Promise<ReportResponse> => {
    const baseParams: Record<string, string> = {
      pageSize: pageSize.toString(),
      pageNumber: pageNumber.toString(),
    };

    if (searchTerm) baseParams.searchTerm = searchTerm;
    if (sortBy) baseParams.sortBy = sortBy;
    if (order) baseParams.order = order;
    if (status) baseParams.status = status;

    if (startDate) {
      baseParams.startDate = startDate;
    }

    if (endDate) {
      baseParams.endDate = endDate;
    }

    const reportTypeListParams = groupTypeList
      ? groupTypeList.map((type) => `ReportGroupTypeList=${type}`).join('&')
      : '';

    const endpoint = `${API_ENDPOINTS.REPORTS}?${new URLSearchParams(
      baseParams
    ).toString()}&${reportTypeListParams}`;

    return reportClient.get<ReportResponse>(endpoint);
  };

  return useQuery(
    [
      'reports',
      pageSize,
      pageNumber,
      searchTerm,
      sortBy,
      order,
      status,
      groupTypeList,
      startDate,
      endDate,
    ],
    fetchReports
  );
}

export function useReportPreviewQuery({
  reportId,
}: UseReportsPreviewQueryOptions = {}): UseQueryResult<ReportPreviewResponse> {
  const reportClient = useHttpClient();
  const fetchReportPreview = async (): Promise<ReportPreviewResponse> => {

    const endpoint = `${API_ENDPOINTS.REPORTS}/${reportId}/details`;    
    return reportClient.get<ReportPreviewResponse>(endpoint);

  };

  return useQuery(['reportPreview', reportId], fetchReportPreview);
}
export function useSecurityReportPreviewQuery<T>({
  reportId,
  selectedTab,
}: UseReportsPreviewQueryOptions & { selectedTab?: string }): UseQueryResult<SecurityV2ReportPreviewResponse<T>> {

  const reportClient = useHttpClient();
  const fetchReportPreview = async (): Promise<SecurityV2ReportPreviewResponse<T> | null> => {
    const endpoint = `${API_ENDPOINTS.REPORTS}/${reportId}/details`;
    const tab = SecurityReportTabs[selectedTab || ""] || selectedTab;

    try {
      const resultObject = await reportClient.get<any>(endpoint);
      const parsedJsonReportData = JSON.parse(resultObject.jsonReportData);
      const components = parsedJsonReportData?.components?.filter(
        (component: any) => component?.keyParameters?.some((kp: any) => kp.key === tab)
      );
      let items: T[] = components.map((component: any) => ({
        type: component.type,
        parameters: component.keyParameters?.find((kp: any) => kp.key === tab)?.parameters
      }));

      if (tab === SecurityReportTabs["key-recommendations"]) {
        const rows = components[0]?.keyParameters?.find((kp: any) => kp.key === tab)?.parameters?.Rows || [];
        items = rows.flat().map((row: any) => {

          const item: { [key: string]: string } = {};
          Object.keys(row).forEach(key => {
            const newKey = key.charAt(0).toLowerCase() + key.slice(1); //lowercase only first letter
            item[newKey] = row[key];
          });
          return item;
        }).sort((a: SecurityReportKeyRecommendation, b: SecurityReportKeyRecommendation) =>
          securityReportSeverityOrder[a.recommendationSeverity]
          - securityReportSeverityOrder[b.recommendationSeverity]
        );
      }

      return {
        currentPage: 1,
        itemCount: items.length,
        items: items,
        pageSize: items.length,
        totalItemCount: items.length,
        totalPageCount: 1,
      };

    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return useQuery(['securityReportPreview', reportId], fetchReportPreview);
}

export const useReportPdfUrlPreviewQuery = (
  reportId: string | undefined
): UseMutationResult<unknown, unknown, void, unknown> => {
  const groupsClient = useHttpClient();
  const API_ENDPOINT = `${API_ENDPOINTS.REPORTS}/${reportId}/download-url`;

  return useMutation(() => groupsClient.get<any>(API_ENDPOINT));
};

export function useReportFiltersQuery(): UseQueryResult<ReportFiltersResponse> {
  const reportClient = useHttpClient();
  const fetchReportFilters = async (): Promise<ReportFiltersResponse> => {
    const endpoint = `${API_ENDPOINTS.REPORTS_FILTER}`;

    return reportClient.get<ReportFiltersResponse>(endpoint);
  };

  return useQuery(['reportsFilters'], fetchReportFilters);
}
