import React from 'react';

type SeverityType = 'High' | 'Medium' | 'Low' | 'Unclassifed';

interface ImpactBadgeProps {
  severity: SeverityType;
}

export const ImpactBadge: React.FC<ImpactBadgeProps> = ({ severity }) => {
  if (severity === 'Unclassifed') {
    return (
      <div className="flex justify-end">
        <span className="inline-flex items-center px-3 py-1 rounded-full bg-white text-sm text-gray-700 border border-gray-400">
          <svg
            className="w-3.5 h-3.5 mr-1.5"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="8" r="7" stroke="currentColor"/>
            <text x="8" y="11" textAnchor="middle" fill="currentColor" fontSize="10">i</text>
          </svg>
          {severity}
        </span>
      </div>
    );
  }

  const getImpactStyles = () => {
    switch (severity) {
      case 'High':
        return 'bg-red-50 text-red-600 border border-red-300';
      case 'Medium':
        return 'bg-yellow-50 text-yellow-700 border border-yellow-300';
      case 'Low':
        return 'bg-blue-50 text-blue-600 border border-blue-300';
      default:
        return '';
    }
  };

  return (
    <div className="flex justify-end">
      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm ${getImpactStyles()}`}>
        <svg
          className="w-3.5 h-3.5 mr-1"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="8" cy="8" r="7" stroke="currentColor"/>
          <text x="8" y="11" textAnchor="middle" fill="currentColor" fontSize="9">!</text>
        </svg>
        {severity}
      </span>
    </div>
  );
};