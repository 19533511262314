import { DEFAULT_PAGE_SIZE, HUB_USERS_TABLE_COLUMNS } from '@common/constants';
import { HUB_USERS_DROPDOWN_ACTIONS } from '@common/dropdownConstants';
import { TableWrapper } from '@components/partials';
import { Banner, Button, Loader, PlusIcon } from '@components/ui';
import { useUsersQuery, useReinviteUser } from '@hooks/users';
import { User, UserRoles } from '@hooks/users/types';
import { usePagination } from '@hooks/utils/pagination';
import useAuthStore from '@store/authStore';
import { useTableData } from '@utils/index';
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AccessLevelModal } from './Modals/AccessLevelModal';
import { CreateEditUserModal } from './Modals/CreateEditUserModal';
import { LockUnlockUserModal } from './Modals/LockUnlockUserModal';
import { RemoveUserModal } from './Modals/RemoveUserModal';
import { ResetPasswordModal } from './Modals/ResetPasswordModal';
import { createCellValue } from './utils';
import { useStickyElements } from '@hooks/utils/stickyElements';
import { Variant } from '@components/ui/Toast';
import useUIStore from '@store/uiStore';
import { ResetMFAModal } from './Modals/ResetMFAModal';

const countSuperAdmins = (users: User[]) => {
  return users.reduce((count, user) => {
    if (user.role === UserRoles.SUPERADMIN) {
      return count + 1;
    }
    return count;
  }, 0);
};


const pageSize = DEFAULT_PAGE_SIZE;

const HubSettingsUsersPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { pageNumber, setPageNumber } = usePagination();
  const [isCreateEditUserModalOpen, setIsCreateEditUserModalOpen] =
    useState(false);
  const [isAccessLevelModalOpen, setIsAccessLevelModalOpen] = useState(false);
  const [isLockUnlockModalOpen, setIsLockUnlockModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isResetMFAModalOpen, setIsResetMFAModalOpen] = useState(false);
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [userForModals, setUserForModals] = useState<User | undefined>(
    undefined
  );
  const [dropDownAction, setDropDownAction] = useState<string | undefined>(
    undefined
  );
  const query = useUsersQuery({ pageSize, pageNumber });
  const userRole = useAuthStore.getState().role;
  const localAccountId =
    useAuthStore.getState().accounts[0]?.localAccountId || '';
  const getTableData = useTableData(
    query,
    HUB_USERS_TABLE_COLUMNS,
    createCellValue
  );

  const { mutateAsync: reinviteUserAsync } = useReinviteUser(userForModals?.id);
  const { addToast } = useUIStore();

  useStickyElements();

  const handleCreateUser = () => {
    setIsCreateEditUserModalOpen(true);
  };

  useEffect(() => {
    switch (dropDownAction) {
      case 'editUserDetails':
        setIsCreateEditUserModalOpen(true);
        break;
      case 'editAccessLevel':
        setIsAccessLevelModalOpen(true);
        break;
      case 'resetPassword':
        setIsResetModalOpen(true);
        break;
      case 'resetMFA':
        setIsResetMFAModalOpen(true);
        break;
      case 'lockAccount':
        setIsLockUnlockModalOpen(true);
        break;
      case 'removeUser':
        setIsRemoveModalOpen(true);
        break;
      case 'resendInvitation':
        reinviteUserAsync().then(() => {
          addToast({
            variant: Variant.Success,
            message: `We’ve resent ${userForModals?.fullName}’s welcome email.`,
            closeable: true,
          })
        }).catch(() => {
          addToast({
            variant: Variant.Error,
            message: 'Something went wrong, and your changes haven’t been saved.',
            closeable: true,
          });
        })
        break;
      default:
        break;
    }
  }, [userForModals, dropDownAction, reinviteUserAsync, addToast]);

  const handleOpenAccessLevelModalNewUser = (value: boolean, user: any) => {
    const userData: any = {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      phone: user.phone,
    };
    setUserForModals(userData);
    setIsCreateEditUserModalOpen(false);
    setIsAccessLevelModalOpen(value);
  };

  if (query.isLoading) {
    return <Loader />;
  }

  const haveResults =
    !query.isLoading && !query.isError && !!query?.data?.items.length;

  return (
    <>
      {userRole !== UserRoles.STANDARD && (
        <Button
          variant="primary"
          onClick={handleCreateUser}
          className="relative mb-5 w-full md:w-fit xl:absolute xl:right-[20px] xl:top-[125px] xl:mb-0"
        >
          <div className="flex items-center justify-center">
            <PlusIcon /> <p className="px-2">Add a user</p>
          </div>
        </Button>
      )}
      {isBannerVisible && (
        <Banner
          message="Click the icon at the right of a row to edit a user’s details, reset their password or unlock their account."
          closeable={true}
          className="mb-6"
          onClose={() => setIsBannerVisible(false)}
        />
      )}

      {haveResults && (
        <TableWrapper
          columns={HUB_USERS_TABLE_COLUMNS}
          data={getTableData()}
          query={query}
          setPageNumber={setPageNumber}
          isCollapsable={true}
          sliceColumns={1}
          isSimpleTable={true}
          isDownloadAvailable={false}
          dropDownData={HUB_USERS_DROPDOWN_ACTIONS}
          setDropDownAction={setDropDownAction}
          setDropDownTableRow={(id) => {
            const selectedUser = (query.data?.items || []).find(
              (user) => user.id === id
            );
            setUserForModals(selectedUser);
          }}
          showDropDownMobile={userRole !== UserRoles.STANDARD}
        />
      )}

      <CreateEditUserModal
        user={userForModals}
        usersData={query.data?.items || []}
        isModalOpen={isCreateEditUserModalOpen}
        handleModalClose={() => {
          setIsCreateEditUserModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsCreateEditUserModalOpen(false);
          queryClient.invalidateQueries('hubUsers');
          if (localAccountId === userForModals?.id) {
            queryClient.invalidateQueries('currentUser');
          }
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        openAccessLevelModalNewUser={handleOpenAccessLevelModalNewUser}
      />

      <AccessLevelModal
        user={userForModals}
        isModalOpen={isAccessLevelModalOpen}
        handleModalClose={() => {
          setIsAccessLevelModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsAccessLevelModalOpen(false);
          queryClient.invalidateQueries('hubUsers');
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        superAdminCount={countSuperAdmins(query.data?.items || [])}
      />

      <LockUnlockUserModal
        user={userForModals}
        isModalOpen={isLockUnlockModalOpen}
        handleModalClose={() => {
          setIsLockUnlockModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsLockUnlockModalOpen(false);
          queryClient.invalidateQueries('hubUsers');
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
      />

      <RemoveUserModal
        user={userForModals}
        isModalOpen={isRemoveModalOpen}
        handleModalClose={() => {
          setIsRemoveModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsRemoveModalOpen(false);
          setPageNumber(1);
          queryClient.invalidateQueries('hubUsers');
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
      />

      <ResetMFAModal
        user={userForModals}
        isModalOpen={isResetMFAModalOpen}
        handleModalClose={() => {
          setIsResetMFAModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsResetMFAModalOpen(false);
          setPageNumber(1);
          queryClient.invalidateQueries('hubUsers');
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
      />

      <ResetPasswordModal
        user={userForModals}
        isModalOpen={isResetModalOpen}
        handleModalClose={() => {
          setIsResetModalOpen(false);
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
        handleSuccess={() => {
          setIsResetModalOpen(false);
          queryClient.invalidateQueries('hubUsers');
          setDropDownAction(undefined);
          setUserForModals(undefined);
        }}
      />
    </>
  );
};
export default HubSettingsUsersPage;