import * as React from 'react';
import { IconProps } from './types';

export const ResetMFAIcon: React.FC<IconProps> = ({
    color = 'text-primaryBrandColor-100',
    classNames = '',
    size = 5,
}) => {
    return (
        <svg className={`stroke-current w-${size} h-${size} ${color} ${classNames}`}
        width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2967_7169)">
                <path d="M15.9998 4.57141H7.99979C7.36861 4.57141 6.85693 5.08309 6.85693 5.71427V18.2857C6.85693 18.9169 7.36861 19.4286 7.99979 19.4286H15.9998C16.631 19.4286 17.1426 18.9169 17.1426 18.2857V5.71427C17.1426 5.08309 16.631 4.57141 15.9998 4.57141Z" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.4282 16.5714H12.5711" stroke={color} strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_2967_7169">
                    <rect width="12" height="16" fill="white" transform="translate(6 4)" />
                </clipPath>
            </defs>
        </svg>
    );
};
