import { AccountInfo } from '@azure/msal-browser';
import {
  CurrentUserResponse,
  ModalsStatus,
  UserRoles,
} from '@hooks/users/types';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AuthStore = {
  isAuthenticated: boolean;
  token: string | null;
  tokenWithScope: string | null;
  accounts: AccountInfo[];
  role: UserRoles | null;
  modalsStatus: ModalsStatus;
  selectedProfile: number | null;
  user: CurrentUserResponse | null;
  isTermsAndCondtitionsGranted: boolean;
  isDataFetched?: boolean;
};

type AuthStoreActions = {
  setIsAuthenticated: (value: boolean) => void;
  setToken: (token: string | null) => void;
  setTokenWithScope: (tokenWithScope: string | null) => void;
  setAccounts: (accounts: AccountInfo[]) => void;
  setUserRole: (role: UserRoles | null) => void;
  setModalsStatus: (status: ModalsStatus) => void;
  setHasSelectedProfile: (status: number | null) => void;
  setUser: (status: CurrentUserResponse) => void;
  setTermsAndConditions: (value: boolean) => void;
  setIsDataFetched: (value: boolean) => void;
};

const useAuthStore = create(
  devtools(
    persist<AuthStore & AuthStoreActions>(
      (set) => ({
        user: null,
        token: null,
        tokenWithScope: null,
        accounts: [],
        isAuthenticated: false,
        role: null,
        selectedProfile: 0,
        isTermsAndCondtitionsGranted: false,
        isDataFetched: false,
        modalsStatus: {
          None: false,
          HubSettingsModal: false,
          HelpSupportModal: false,
          DashboardModal: false,
          ReportsModal: false,
          InsightsAnalyticsModal: false,
          PeopleGroupsLocationsModal: false,
        },
        setTermsAndConditions: (value) =>
          set({ isTermsAndCondtitionsGranted: value }),
        setIsAuthenticated: (value) => set({ isAuthenticated: value }),
        setToken: (token) => set({ token }),
        setTokenWithScope: (tokenWithScope) => set({ tokenWithScope }),
        setAccounts: (accounts) => set({ accounts }),
        setUser: (value) => set({ user: value }),
        setUserRole: (role) => set({ role }),
        setModalsStatus: (status) => set({ modalsStatus: status }),
        setHasSelectedProfile: (status) => set({ selectedProfile: status }),
        setIsDataFetched: (isDataFetched) => set({ isDataFetched }),
      }),
      { name: 'auth' }
    ),
    { enabled: false }
  )
);

export default useAuthStore;
