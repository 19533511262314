export const convertReportTitle = (title: string): string => {
  const titleMap: { [key: string]: string } = {
    'Key recommendations': 'Key recommendations', // No change needed
    'Login activity': 'Login security',
    'SuspiciousActivity': 'Suspicious activity',
    'Mailboxes': 'Mailbox security',
    'Outdated OS & Browsers': 'Browser and OS risks'
  };

  // Check for variations of 'MS SecureScore'
  if (/^MS SecureScore( \d+)?$/.test(title)) {
    return 'Microsoft Secure Score';
  }
  if (title.includes('MS')) {
    return title.replace(/MS/g, 'Microsoft');
  }

  return titleMap[title] || title;
};