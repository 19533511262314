import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { SecurityV2ReportSecureScoreResponse } from '@hooks/reports/types';
import styles from '@pages/InsightsAndAnalytics/Security/MsSecureScore/styles.module.css';
import {
  LineChartTile,
  LineChartTypes,
  LineChartTickFormat,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  Table,
  LineChartIconUp,
  LineChartIcon,
  MinusIcon,
} from '@components/ui';
import {
  DashboardTiles,
  REPORT_SUSPICIOUS_ACTIVITY_TABLE_COLUMNS,
} from '@common/constants';
import { formatDate, useTableData } from '@utils/index';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';
import { createCellValue } from '@pages/InsightsAndAnalytics/Security/SuspiciousActivity/Accounts/utils';
import Map from '@components/ui/Map';
import useTransformData from '@hooks/utils/transformData';
import {
  getSuspiciousActivityTypesText,
  getSuspiciousActivityTypes,
  getSuspiciousActivityCountryText,
  getReportPreviousPeriodByDateRange,
} from '../utils';

type Change = 'up' | 'down' | 'stayed the same';

export const ReportSuspiciousActivityPage: React.FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop();

  const query =
    useSecurityReportPreviewQuery<SecurityV2ReportSecureScoreResponse>({
      reportId,
      selectedTab,
    });
  const items = query?.data?.items || [];

  const activityChart = items.find(
    (item) => item.type === DashboardTiles.SuspiciousActivityLineChart
  )?.parameters;
  const tableData = items.find(
    (item) => item.type === DashboardTiles.Table
  )?.parameters;
  const totalEventsData = items.find(
    (item) => item.type === DashboardTiles.SuspiciousActivityTotalEvents
  )?.parameters;
  const typesData = items.find(
    (item) => item.type === DashboardTiles.SuspiciousActivityType
  )?.parameters;
  const mapData = items.find(
    (item) => item.type === DashboardTiles.SuspiciousActivityMap
  )?.parameters;
  const info = items.find(
    (item) => item.type === DashboardTiles.SuspiciousActivityInfo
  )?.parameters;

  const { data, legendRanges } = useTransformData(mapData?.data ?? []);

  const firstOfThePeriod = activityChart?.data[0][0];
  const lastOfThePeriod =
    activityChart?.data[0][activityChart?.data[0]?.length - 1];

  const activityChange: Change = (
    firstOfThePeriod < lastOfThePeriod
      ? 'up'
      : firstOfThePeriod > lastOfThePeriod
      ? 'down'
      : 'stayed the same'
  ) as Change;

  const displayText =
    info?.signInsPercentageChange > 0
      ? true
      : info?.signInsPercentageChange < 0
      ? false
      : null;

  const { increasedTypes, decreasedTypes } = getSuspiciousActivityTypes(
    typesData?.percentText
  );

  const labels = activityChart?.labels.map((item: string) => formatDate(item));

  return (
    <>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Suspicious activity</p>
          <div className="ml-4 mt-2 flex flex-row items-start">
            {activityChange === 'up' && (
              <LineChartIconUp color="text-successColor-100" size="4" />
            )}
            {activityChange === 'down' && (
              <LineChartIcon size="4" color="text-errorColor-100" />
            )}
            {activityChange === 'stayed the same' && (
              <MinusIcon
                size="4"
                color="text-interfaceColor-100"
                withoutCircle={true}
                classNames="mt-[2px]"
              />
            )}

            <span
              className={`ml-2 flex items-center text-xs ${
                activityChange === 'up'
                  ? 'text-successColor-100'
                  : activityChange === 'down'
                  ? 'text-errorColor-100'
                  : 'text-interfaceColor-80'
              }`}
            >
              {activityChange === 'up'
                ? 'More secure'
                : activityChange === 'down'
                ? 'Less secure'
                : activityChange === 'stayed the same'
                ? 'The same'
                : ''}
            </span>
          </div>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {displayText
              ? `Suspicious activity trended ${
                  activityChange === 'stayed the same'
                    ? 'the same'
                    : activityChange
                } this month, with increases in ${getSuspiciousActivityTypesText(
                  increasedTypes,
                  decreasedTypes
                )}.
                        The majority of your suspicious activity came from 
                        ${getSuspiciousActivityCountryText(mapData?.data)}. 
                        ${
                          tableData?.data?.length
                        } accounts were responsible for ${
                          totalEventsData?.total
                } events, an increase of ${totalEventsData?.percentage}% compared to the previous ${getReportPreviousPeriodByDateRange(info?.previousPeriod)}.
                       `
              : 'Here is a breakdown of any suspicious activity you have had within the reporting period.'}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        <LineChartTile
          classNames={'col-span-3 lg:col-span-3 xl:col-span-3'}
          sortData={[]}
          tooltipLabel={'suspicious activities'}
          showCompareDropdown={false}
          showAgainstDropdown={false}
          contextData={[]}
          headerTitle={'Suspicious activity'}
          isLoading={false}
          labels={labels || undefined}
          data={activityChart?.data}
          compareLabel=""
          dropdownMenuItemClasses={'!w-[300px]'}
          dataSetTypes={[LineChartTypes.Dots]}
          dataSetColors={['#00CF6C']}
          showYTicks={true}
          showDateRange={true}
          showXTicks={true}
          isDotted={true}
          chartHeight={320}
          xTickFormat={LineChartTickFormat.Date}
          yTickFormat={LineChartTickFormat.Number}
        />
        <div className="!flex-column !justify-center">
          <NumberTile
            dropdownMenuItemClasses={'!w-[300px]'}
            classNames={'col-span-3 md:col-span-2 xl:col-span-2 mb-9'}
            key={'Suspicious activity: Total events'}
            sortData={undefined}
            contextData={[]}
            percentageLabel={''}
            headerTitle={'Suspicious activity: Total events'}
            isLoading={false}
            data={totalEventsData ? [totalEventsData?.total, totalEventsData?.percentage] : []}
            isPercentIncreased={totalEventsData?.isPercentIncreased}
            isArrowColorReversed={true}
          />
          <DoughnutChartTile
            classNames={'col-span-3 md:col-span-2 xl:col-span-2'}
            headerTitle={'Suspicious activity: Type'}
            dropdownMenuItemClasses={'!w-[300px]'}
            data={typesData?.data || []}
            dataLabels={typesData?.legends || []}
            isLoading={false}
            showBracketLabelValue={false}
            contextData={[]}
            key={'Suspicious activity: Type'}
          />
        </div>
      </div>
      <div className="relative mt-2">
        <div className="p-4 text-24">
          <span>Suspicious activity by country</span>{' '}
        </div>
        <Map rawData={data} label={'events'} legendRanges={legendRanges} />
      </div>
      <div className="col-span-6 mt-2 lg:col-span-6 xl:col-span-6">
        <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
          <div className="p-4 text-24">
            <span>Accounts responsible for the most suspicious activity</span>
          </div>

          <Table
            columns={REPORT_SUSPICIOUS_ACTIVITY_TABLE_COLUMNS}
            data={useTableData(
              { isFetched: true, data: { items: tableData?.data } },
              REPORT_SUSPICIOUS_ACTIVITY_TABLE_COLUMNS,
              createCellValue,
              true
            )()}
            query={undefined}
            isLoading={false}
            totalCount={10}
            currentPage={1}
            totalPages={1}
            setPageNumber={() => 1}
            handleSelect={() => null}
            isFirstColumnSticky={false}
            isGrayHeader={true}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
};
