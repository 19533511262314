import { TextTile } from '@pages/InsightsAndAnalytics/tiles/TextTile';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { AvailableServices } from '@pages/HubSettings/ConnectedServices';
import { ServiceIntergrationResponse } from '@hooks/connectedServices/types';
import { LightInfoIcon } from '@components/ui';


export type Props = {
    connectedServicesData: ServiceIntergrationResponse[] | undefined
};

const RecommendationsBox: React.FC<Props> = ({ connectedServicesData }) => {

    const showLinkForService = (service: number) => {
        return (
            connectedServicesData && Array.isArray(connectedServicesData) &&
            connectedServicesData?.some((obj) => obj.vendor === service)
        );
    };

    return (
        <TextTile classNames={styles.textTile} headerTitle={'Recommendations'}>
            <div className={styles.textTileContainer}>
                <div className={styles.textTileItem}>
                    <LightInfoIcon
                        color={styles.textTileItemIcon}
                        classNames="w-4 h-4"
                    />
                    <span>
                        Consider running a training programme to make sure people know
                        what’s included in their license.
                    </span>
                </div>
                <div className={styles.textTileItem}>
                    <LightInfoIcon
                        color={styles.textTileItemIcon}
                        classNames="w-4 h-4"
                    />
                    <span>
                        If people don’t need the parts of their license they’re not
                        using, consider downgrading.
                    </span>
                </div>
                <div className={styles.textTileLinkItem}>
                    {showLinkForService(AvailableServices.RINGCENTRAL) && (
                        <Link
                            className="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            to="https://support.ringcentral.com/article-v2/Managing-licenses-in-RingCentral-account.html?brand=RC_US&product=RingCentral_MVP&language=en_US"
                        >
                            View and manage your RingCentral licenses
                        </Link>
                    )}
                    {showLinkForService(AvailableServices.MICROSOFT) && (
                        <Link
                            className="underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            to="https://learn.microsoft.com/en-us/windows-365/business/assign-unassign-license"
                        >
                            More information on expired licenses
                        </Link>
                    )}
                </div>
            </div>
        </TextTile>
    );
};

export default RecommendationsBox;
