import React from 'react';
import styles from '.././styles.module.css';
import { useLocation, useParams } from 'react-router-dom';
import { useSecurityReportPreviewQuery } from '@hooks/reports';
import { SecurityV2ReportSecureScoreResponse } from '@hooks/reports/types';
import { createCellValue as createBrowsersCellValue } from '@pages/InsightsAndAnalytics/Security/OutdatedBrowsers/Accounts/utils';
import { createCellValue as createSystemsCellValue } from '@pages/InsightsAndAnalytics/Security/OutdatedOSData/Accounts/utils';
import { NumberTile } from '@pages/InsightsAndAnalytics/tiles/NumberTile';
import {
  Table,
  LineChartIconUp,
  LineChartIcon,
  MinusIcon,
} from '@components/ui';
import {
  DashboardTiles,
  REPORTS_OUTDATED_BROWSER_TABLE_COLUMN,
  REPORTS_OUTDATED_OS_TABLE_COLUMNS,
} from '@common/constants';
import { useTableData } from '@utils/index';
import { DoughnutChartTile } from '@pages/InsightsAndAnalytics/tiles/DoughnutChartTile';

type Change = 'up' | 'down' | 'stayed the same';

export const ReportBrowserAndOSVersionsPage: React.FC = () => {
  const { reportId } = useParams<{ reportId: string }>();
  const location = useLocation();
  const selectedTab = location.pathname.split('/').pop();

  const query =
    useSecurityReportPreviewQuery<SecurityV2ReportSecureScoreResponse>({
      reportId,
      selectedTab,
    });
  const items = query?.data?.items || [];
  const tablesData = items.filter((item) => item.type === DashboardTiles.Table);
  const outdatedBrowserLogins = items.find(
    (item) => item.type === DashboardTiles.OutdatedBrowserLogins
  )?.parameters;
  const outdatedBrowsersUsed = items.find(
    (item) => item.type === DashboardTiles.OutdatedBrowsersUsed
  )?.parameters;
  const usersWithOutdatedBrowsers = items.find(
    (item) => item.type === DashboardTiles.UsersWithOutdatedBrowsers
  )?.parameters;
  const browserTable = tablesData[0]?.parameters;

  const outdatedSystemsLogins = items.find(
    (item) => item.type === DashboardTiles.OutdatedSystemsLogins
  )?.parameters;
  const outdatedSystemsUsed = items.find(
    (item) => item.type === DashboardTiles.OutdatedSystemsUsed
  )?.parameters;
  const usersWithOutdatedSystems = items.find(
    (item) => item.type === DashboardTiles.UsersWithOutdatedSystems
  )?.parameters;
  const systemsTable = tablesData[1]?.parameters;

  const browsersInfo = items.find(
    (item) => item.type === DashboardTiles.OutdatedBrowsersInfo
  )?.parameters;
  const systemsInfo = items.find(
    (item) => item.type === DashboardTiles.OutdatedSystemsInfo
  )?.parameters;

  const hasBrowserData = !!browserTable?.data;
  const hasSystemData = !!systemsTable?.data;
  const browserChange: Change =
    browsersInfo?.oudatedBrowserTrend === null
      ? 'stayed the same'
      : browsersInfo?.oudatedBrowserTrend
      ? 'up'
      : 'down';
  const systemChange: Change =
    systemsInfo?.oudatedOSTrend === null
      ? 'stayed the same'
      : systemsInfo?.oudatedOSTrend
      ? 'up'
      : 'down';

  return (
    <>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Logins from outdated browsers</p>
          <div className="ml-4 mt-2 flex flex-row items-start">
            {browserChange === 'up' && (
              <LineChartIconUp color="text-successColor-100" size="4" />
            )}
            {browserChange === 'down' && (
              <LineChartIcon size="4" color="text-errorColor-100" />
            )}
            {browserChange === 'stayed the same' && (
              <MinusIcon
                size="4"
                color="text-interfaceColor-100"
                withoutCircle={true}
                classNames="mt-[2px]"
              />
            )}

            <span
              className={`ml-2 flex items-center text-xs ${
                browserChange === 'up'
                  ? 'text-successColor-100'
                  : browserChange === 'down'
                  ? 'text-errorColor-100'
                  : 'text-interfaceColor-80'
              }`}
            >
              {browserChange === 'up'
                ? 'More secure'
                : browserChange === 'down'
                ? 'Less secure'
                : browserChange === 'stayed the same'
                ? 'No change'
                : ''}
            </span>
          </div>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {hasBrowserData
              ? `You had ${
                  outdatedBrowserLogins?.isPercentIncreased ? 'more' : 'fewer'
                } logins from outdated browsers this month, and the number 
                        of users with outdated browsers ${
                          usersWithOutdatedBrowsers?.isPercentIncreased
                            ? 'increased'
                            : 'decreased'
                        }.
                        ${
                          browsersInfo?.popularOutdatedBrowserName
                            ? `The most popular outdated browser was
                         ${browsersInfo?.popularOutdatedBrowserName}, accounting for 
                         ${browsersInfo?.popularOutdatedBrowserPercentage}% of all outdated logins.`
                            : ''
                        }
                        `
              : 'Here is a breakdown of any outdated browsers identified over the reporting period.'}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        <NumberTile
          dropdownMenuItemClasses={'!w-[300px]'}
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          key={'Logins: outdated browsers'}
          sortData={undefined}
          contextData={[]}
          headerTitle={'Logins: outdated browsers'}
          isLoading={false}
          percentageLabel={''}
          isPercentage={true}
          isArrowColorReversed={true}
          isPercentIncreased={outdatedBrowserLogins?.isPercentIncreased}
          data={[outdatedBrowserLogins?.total, outdatedBrowserLogins?.percentage]}
          bottomLabel="Total logins by outdated browsers"
        />

        <DoughnutChartTile
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          headerTitle={'Outdated browsers used'}
          dropdownMenuItemClasses={'!w-[300px]'}
          data={outdatedBrowsersUsed?.data || []}
          dataLabels={outdatedBrowsersUsed?.legends || []}
          isLoading={false}
          contextData={[]}
          key={'Outdated browsers used'}
          showBracketLabelValue={false}
        />
        <DoughnutChartTile
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          headerTitle={'Users with outdated browsers'}
          dropdownMenuItemClasses={'!w-[300px]'}
          data={usersWithOutdatedBrowsers?.data || []}
          dataLabels={usersWithOutdatedBrowsers?.legends || []}
          isLoading={false}
          key={'Users with outdated browsers'}
          contextData={[]}
          showBracketLabelValue={false}
        />
      </div>
      <div className={styles.pageGrid}>
        <div className="col-span-6 mt-[-20px] lg:col-span-6 xl:col-span-6">
          <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
            <div className="p-4 text-24">
              <span>Most active accounts using outdated browsers</span>
            </div>
            <Table
              columns={REPORTS_OUTDATED_BROWSER_TABLE_COLUMN}
              data={useTableData(
                { isFetched: true, data: { items: browserTable?.data } },
                REPORTS_OUTDATED_BROWSER_TABLE_COLUMN,
                createBrowsersCellValue,
                true
              )()}
              query={undefined}
              isLoading={false}
              totalCount={10}
              currentPage={1}
              totalPages={1}
              setPageNumber={() => 1}
              handleSelect={() => null}
              isFirstColumnSticky={false}
              isGrayHeader={true}
              showPagination={true}
            />
          </div>
        </div>
      </div>
      <div className="mt-[20px] flex flex-col items-start gap-4">
        <div className="flex flex-row items-start text-24 md:text-28">
          <p>Logins from outdated operating systems</p>
          <div className="flex ml-4 mt-2 flex-row items-start">
            {systemChange === 'up' && (
              <LineChartIconUp color="text-successColor-100" size="4" />
            )}
            {systemChange === 'down' && (
              <LineChartIcon size="4" color="text-errorColor-100" />
            )}
            {systemChange === 'stayed the same' && (
              <MinusIcon
                size="4"
                color="text-interfaceColor-100"
                withoutCircle={true}
                classNames="mt-[2px]"
              />
            )}

            <span
              className={`ml-2 flex items-center text-xs ${
                systemChange === 'up'
                  ? 'text-successColor-100'
                  : systemChange === 'down'
                  ? 'text-errorColor-100'
                  : 'text-interfaceColor-80'
              }`}
            >
              {systemChange === 'up'
                ? 'More secure'
                : systemChange === 'down'
                ? 'Less secure'
                : systemChange === 'stayed the same'
                ? 'No change'
                : ''}
            </span>
          </div>
        </div>
        <div className="mb-[5px] w-[70%] text-16 md:text-18">
          <p>
            {hasSystemData
              ? ` ${
                  outdatedSystemsLogins?.isPercentIncreased ? 'More' : 'Less'
                } users logged in using outdated operating systems this month. 
                        ${
                          systemsInfo?.popularOutdatedOSName
                            ? `The most popular outdated OS was ${systemsInfo?.popularOutdatedOSName},
                         accounting for ${systemsInfo?.popularOutdatedOSPercentage}% of all outdated OS logins.`
                            : ''
                        }
                        `
              : 'Here is a breakdown of any outdated operating systems identified over the reporting period.'}
          </p>
        </div>
      </div>
      <div className={styles.pageGrid}>
        <NumberTile
          dropdownMenuItemClasses={'!w-[300px]'}
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          key={'Logins: outdated OS'}
          sortData={undefined}
          contextData={[]}
          headerTitle={'Logins: outdated OS'}
          isLoading={false}
          percentageLabel={''}
          data={[outdatedSystemsLogins?.total]}
          bottomLabel="Total logins"
        />
        <DoughnutChartTile
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          headerTitle={'Outdated OSs used'}
          dropdownMenuItemClasses={'!w-[300px]'}
          data={outdatedSystemsUsed?.data || []}
          dataLabels={outdatedSystemsUsed?.legends || []}
          showBracketLabelValue={false}
          isLoading={false}
          contextData={[]}
          key={'Outdated OSs used'}
        />
        <DoughnutChartTile
          classNames={'col-span-3 md:col-span-1 xl:col-span-1'}
          headerTitle={'Users with outdated OSs'}
          dropdownMenuItemClasses={'!w-[300px]'}
          data={usersWithOutdatedSystems?.data || []}
          dataLabels={usersWithOutdatedSystems?.legends || []}
          showBracketLabelValue={false}
          isLoading={false}
          key={'Users with outdated OSs'}
          contextData={[]}
        />
      </div>
      <div className="col-span-6 mt-2 lg:col-span-6 xl:col-span-6">
        <div className="bg-white md:mx-auto lg:rounded-[4px] lg:shadow-shadow-02">
          <div className="p-4 text-24">
            <span>Most active accounts using outdated OSs</span>
          </div>
          <Table
            columns={REPORTS_OUTDATED_OS_TABLE_COLUMNS}
            data={useTableData(
              { isFetched: true, data: { items: systemsTable?.data } },
              REPORTS_OUTDATED_OS_TABLE_COLUMNS,
              createSystemsCellValue,
              true
            )()}
            query={undefined}
            isLoading={false}
            totalCount={10}
            currentPage={1}
            totalPages={1}
            setPageNumber={() => 1}
            handleSelect={() => null}
            isFirstColumnSticky={false}
            isGrayHeader={true}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
};
