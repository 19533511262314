import { CircleRightArrow, ClipboardIcon, SandClockIcon } from '@components/ui';
import { CellBottomPart } from '@components/ui/Table/utils';
import { formatDate, showLastUsedData } from '@utils/index';
import { Link } from 'react-router-dom';

function getReason(data: any, index: number) : string {
  return data[index]['isOverassigned']
    ? `Included in user's ${data[index]['subscriptionName']} subscription`
    : data[index].reason ?? 'Never used';
}

export const createCellValue = (
  property: string,
  data: any,
  index: number,
  isMobile: boolean,
  formatedValue: string
) => {
  const value = data[index][property];

  switch (property) {
    case 'assignedTo':
      return (
        <>
          <span className="flex items-center">
            {data[index]['fullName'] || value }
            <Link
              className="ml-2 mt-[3px] cursor-pointer"
              to={`/accounts/accounts/account/${data[index]['accountId']}`}
            >
              <CircleRightArrow size="4" classNames="mt-[0px]" />
            </Link>
          </span>
          {!isMobile && <CellBottomPart data={data[index]['assignedTo']} />}
        </>
      );
    case 'lastUsed':
      return (
        <>
          <span>{formatDate(value)}</span>
          {showLastUsedData(data[index]['overNDaysAgo'])}
        </>
      );
    case 'licenseType':
      return (
        <>
          <span>{isMobile ? value : formatedValue}</span>
        </>
      );
    case 'redundancyReason':
      return (
        <>
          <span className="flex items-center">
            {data[index]['isOverassigned'] ? 'Overassigned' : 'Inactive'}
          </span>
          {!isMobile && (
            <div className="flex flex-row items-center justify-center">
              {data[index]['isOverassigned'] ? (
              <ClipboardIcon color="#7D848A" classNames="mt-1" />
            ) : (
              <SandClockIcon color="#7D848A" />
            )
          }
              <CellBottomPart
                data={getReason(data, index)}
              />{' '}
            </div>
          )}
        </>
      );
    case 'assignmentDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'expiryDate':
      return (
        <>
          <span>{formatDate(value)}</span>
        </>
      );
    case 'vendor':
      return (
        <>
          <span>{value ?? 'No data available'}</span>
        </>
      );
  }
};
