import React from 'react';
import {
  LineChartTile,
  LineChartTypes,
  LineChartTickFormat,
} from '@pages/InsightsAndAnalytics/tiles/LineChartTile';
import { HorizontalBarChartTile } from '@pages/InsightsAndAnalytics/tiles/HorizontalChartTile';
import { formatNumber } from '@utils/index';
import { DashboardTiles } from '@common/constants';
import { LineChartIcon, LineChartIconUp, MinusIcon } from '@components/ui';

interface MsSecureScore1Props {
  data: {
    type: number;
    parameters: any;
  }[];
}

type TrendDirection = 'higher' | 'lower';
type TrendChange = 'up' | 'down' | 'stayed the same';

export const MsSecureScore1: React.FC<MsSecureScore1Props> = ({ data }) => {
  const changesOverTime = data.find(item => item.type === DashboardTiles.SecureScoreChangesOverTime)?.parameters;
  const comparison = data.find(item => item.type === DashboardTiles.SecureScoreComparison)?.parameters;
  const identity = data.find(item => item.type === DashboardTiles.SecureScoreIdentity)?.parameters;
  const device = data.find(item => item.type === DashboardTiles.SecureScoreDevice)?.parameters;
  const apps = data.find(item => item.type === DashboardTiles.SecureScoreApps)?.parameters;
  const infra = data.find(item => item.type === DashboardTiles.SecureScoreInfrastructure)?.parameters;

  const firstSecureScoreOfThePeriod = changesOverTime?.data[0][0];
  const lastSecureScoreOfThePeriod =
    changesOverTime?.data[0][changesOverTime?.data[0]?.length - 1];

  const trendChange: TrendChange = (
    firstSecureScoreOfThePeriod < lastSecureScoreOfThePeriod
      ? 'up'
      : firstSecureScoreOfThePeriod > lastSecureScoreOfThePeriod
        ? 'down'
        : 'stayed the same'
  ) as TrendChange;
  
  const getScoreMessage = (yourScore: number, comparisonScore: number) => {
    if (yourScore === comparisonScore) return 'the same as';
    if (yourScore > comparisonScore) return 'higher than average';
    return 'lower than average';
  };

  const scoreMessage = getScoreMessage(
    comparison?.yourScore || 0,
    comparison?.comparisonScore || 0
  );
  const message = trendChange === 'up' ? 'Nice work!' : '';

  return (
    <div className="p-6 bg-white">           

        <div className="flex flex-col items-start gap-2 mb-6">
          <div className="flex flex-row items-start text-14 md:text-14">
            <h2 className="text-xl font-normal text-gray-900">Secure Score</h2>
            <div className="ml-3 mt-1 flex flex-row items-start">
              {trendChange === 'up' && (
                <LineChartIconUp color="text-successColor-100" size="4" />
              )}
              {trendChange === 'down' && (
                <LineChartIcon size="4" color="text-errorColor-100" />
              )}
              {trendChange === 'stayed the same' && (
                <MinusIcon
                  size="4"
                  color="text-interfaceColor-100"
                  withoutCircle={true}
                  classNames="mt-[2px]"
                />
              )}
              <span
                className={`ml-2 flex items-center text-xs ${trendChange === 'up'
                  ? 'text-successColor-100'
                  : trendChange === 'down'
                    ? 'text-errorColor-100'
                    : 'text-interfaceColor-80'
                  }`}
              >
                {trendChange === 'up'
                  ? 'More secure'
                  : trendChange === 'down'
                    ? 'Less secure'
                    : 'The same'}
              </span>
            </div>
          </div>
          <div className="w-[100%]">
            <p className="text-sm text-gray-800">
              {`Your Microsoft SecureScore trended ${trendChange === 'stayed the same' ? 'the same' : trendChange
                } this month. Your overall score is tracking 
              ${getScoreMessage(
                  comparison?.yourScore,
                  comparison?.comparisonScore
                )} organisations like yours. ${message}`}
            </p>
          </div>
        </div>

      {/* Line Chart Section - Full Width */}
      <div className="mb-8">  
              <LineChartTile
                tooltipLabel="secure score"
                headerTitle="Secure Score: Changes over time"
                labels={changesOverTime?.labels.map((l: string) => new Date(l).toLocaleDateString()) || []}
                data={changesOverTime?.data || [[]]}
                dataSetTypes={[LineChartTypes.Dots]}
                dataSetColors={['#00CF6C']}
                showYTicks={true}
                showDateRange={false}
                showXTicks={true}
                isDotted={true}
                chartHeight={150}
                xTickFormat={LineChartTickFormat.Date}
                yTickFormat={LineChartTickFormat.Percentage}
                sortData={[{ id: '1', label: 'Default', value: 'default' }]}
                isLoading={false}
                compareData={[]}
                contextData={[]}
                showCompareDropdown={false}
                showAgainstDropdown={false}
              />
                  
      </div>

      {/* Comparison Section */}
      <div className="mb-8">
          <HorizontalBarChartTile
            contextData={[]}
            classNames="w-full bg-white rounded-md shadow-none"
            headerTitle="Secure Score comparison"
            isLoading={false}
            data={[
              parseFloat(comparison?.yourScore?.toFixed(1) || '0'),
              parseFloat(comparison?.comparisonScore?.toFixed(1) || '0'),
            ]}
            dataLabels={[
              `Your score: ${comparison?.yourScore?.toFixed(1) || 0}%`,
              `Organisations like yours: ${comparison?.comparisonScore?.toFixed(1) || 0}%`,
            ]}
            barBackgroundColors={['#00CF6C', '#B65AD7']}
            barPercentage={0.25}
            height={100}
          />
      </div>
      {/* Breakdown Section */}
      <div>
        <div className="border border-gray-200 rounded-md p-3 shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 pb-3">Secure Score breakdown</h3>
          <div className="grid grid-cols-4 gap-6 bg-gray-50 p-3 rounded-md">
            <div>
              <h4 className="text-sm text-gray-800 mb-1">Identity:</h4>
              <p className="text-3xl font-medium text-gray-900">
                {formatNumber(identity?.scorePercentage)}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-800 mb-1">Device:</h4>
              <p className="text-3xl font-medium text-gray-900">
                {formatNumber(device?.scorePercentage)}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-800 mb-1">Apps:</h4>
              <p className="text-3xl font-medium text-gray-900">
                {formatNumber(apps?.scorePercentage)}
              </p>
            </div>
            <div>
              <h4 className="text-sm text-gray-800 mb-1">Infrastructure:</h4>
              <p className="text-3xl font-medium text-gray-900">
                {formatNumber(infra?.scorePercentage)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};