import React from 'react';

interface TableData {
  id: string;
  title: string;
  category: string;
  isCompleted: boolean;
  scoreImpact: number;
}

interface MsSecureScore2Props {
  data: {
    type: number;
    parameters: {
      data: TableData[];
    };
  }[];
}

export const formatScoreImpact = (scoreImpact: number | null): string => {
  if (scoreImpact === null || scoreImpact === 0) {
    return '+0.0%';
  }
  return `+${(Math.round(scoreImpact * 10) / 10).toFixed(1)}%`;
};

export const MsSecureScore2: React.FC<MsSecureScore2Props> = ({ data }) => {
  const tableData = data.find(item => item.type === 110)?.parameters?.data || [];

  const getStatusStyle = (isCompleted: boolean) => {
    return isCompleted
      ? "flex items-center gap-2 text-green-800"  // Updated for completed
      : "flex items-center gap-2 text-gray-700"; 
  };

  const getCategoryColor = (category: string) => {
    switch (category.toLowerCase()) {
      case 'device':
        return 'text-blue-700';
      case 'identity':
        return 'text-purple-700';
      case 'apps':
        return 'text-green-700';
      default:
        return 'text-gray-700';
    }
  };

  return (
    <div className="p-6 bg-white">
      <div>
        <h2 className="text-xl font-normal text-gray-900 mb-2">
          Improve your score
        </h2>
        <p className="text-sm text-gray-800 mb-6 ">
          There&apos;s no such thing as too secure. We&apos;ve highlighted the 10 highest-impact 
          changes you could make to improve your score.
        </p>

        <div className="overflow-hidden border border-gray-200 rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-200">
                <th scope="col" className="px-2 py-4 text-left text-sm font-semibold text-black w-1/2">
                  Improvement action
                </th>
                <th scope="col" className="px-1 py-4 text-left text-sm font-semibold text-black w-1/6">
                  Score impact
                </th>
                <th scope="col" className="px-1 py-4 text-left text-sm font-semibold text-black w-1/6">
                  Category
                </th>
                <th scope="col" className="px-1 py-4 text-left text-sm font-semibold text-black w-1/6">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 text-sm">
              {tableData.map((item, index) => (
                <tr 
                  key={item.id || index}
                  className="hover:bg-gray-50"
                >
                  <td className="px-2 py-4 text-gray-900 font-normal">
                    {item.title}
                  </td>
                  <td className="px-2 py-4 text-gray-900">
                    {formatScoreImpact(item.scoreImpact)}
                  </td>
                  <td className="px-2 py-4">
                    <span className={`font-medium ${getCategoryColor(item.category)}`}>
                      {item.category}
                    </span>
                  </td>
                  <td className="px-2 py-4 text-sm">
                        <span className={getStatusStyle(item.isCompleted)}>
                          {item.isCompleted ? (
                            <>
                              <div className="w-3 h-3 rounded-full bg-green-600"></div>
                              Completed
                            </>
                          ) : (
                            <>
                              <svg className="w-4 h-4 text-gray-400" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
                            </svg>
                              To address
                            </>
                          )}
                        </span>
                      </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};