import React from 'react';
import { ImpactBadge } from './ImpactBadge';

type SeverityType = 'High' | 'Medium' | 'Low' | 'Unclassifed';

interface RecommendationParameters {
  RecommendationName: string;
  RecommendationSeverity: SeverityType;
}

interface Component {
  parameters: RecommendationParameters;
}

interface KeyRecommendationsProps {
  components: Component[];
}

interface SeverityCounts {
  [key: string]: number;
}

export const KeyRecommendations: React.FC<KeyRecommendationsProps> = ({ components }) => {
  const severityCounts: SeverityCounts = { High: 0, Medium: 0, Low: 0, Unclassifed: 0 };
  const recommendations = components.map((component) => component.parameters);

  recommendations.forEach((rec) => {
    severityCounts[rec.RecommendationSeverity]++;
  });

  const sortedRecommendations = [...recommendations].sort((a, b) => {
    const severityOrder: { [key in SeverityType]: number } = {
      High: 1,
      Medium: 2,
      Low: 3,
      Unclassifed: 4
    };
    return severityOrder[a.RecommendationSeverity] - severityOrder[b.RecommendationSeverity];
  });

  return (
    <div className="max-w-5xl mx-auto p-5">
      <div className="grid grid-cols-3 gap-5 mb-8">
        <div className="bg-[#E31B0C] rounded-lg p-5 text-white">
          <div className="text-[80px] leading-tight font-normal mb-1">{severityCounts.High}</div>
          <div className="text-sm font-normal">High impact</div>
        </div>
        <div className="bg-[#F7CF1D] rounded-lg p-5 text-black">
          <div className="text-[80px] leading-tight font-normal mb-1">{severityCounts.Medium}</div>
          <div className="text-sm font-normal">Medium impact</div>
        </div>
        <div className="bg-[#54B5F7] rounded-lg p-5 text-black">
          <div className="text-[80px] leading-tight font-normal mb-1">{severityCounts.Low}</div>
          <div className="text-sm font-normal">Low impact</div>
        </div>
      </div>

      <div className="mb-4">
        <h2 className="text-lg font-medium text-gray-900 mb-1">Your top recommendations</h2>
        <p className="text-sm text-gray-600">Head to Reports in Clevr360 to see all your recommendations.</p>
      </div>

      <div className="rounded-lg overflow-hidden border border-gray-200">
        <table className="w-full p-2">
          <thead>
            <tr className="bg-[#F1F3F5]">
              <th className="px-3 py-3 text-left text-sm font-normal text-gray-700"><strong>Risk</strong></th>
              <th className="px-3 py-5 text-right text-sm font-normal text-gray-700"><strong>Impact</strong></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedRecommendations.map((rec, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="px-3 py-4 text-sm text-gray-900">
                  {rec.RecommendationName}
                </td>
                <td className="px-3 py-4 text-right flex justify-end">
                  <ImpactBadge severity={rec.RecommendationSeverity} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};