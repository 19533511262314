import { FilterData } from '@components/partials/TableWrapper/parts/Filter';
import { SortData } from '@components/partials/TableWrapper/parts/Sort';
import { Column } from '@components/ui';
import {
  Licenses,
  LicensesExpired,
  LicensesExpiring,
  LicensesUsage,
  MfaStatus,
  MostDelegatesMailbox,
  NoLicences,
  SecureScore,
  SharedMailboxes,
} from '@hooks/licenses/types';
import { Person, PersonLicenses } from '@hooks/people/types';
import { Reports } from '@hooks/reports/types';
import { UserColumn } from '@hooks/users/types';
import { InsightProfile, VendorType } from './types';
import LicensesAndPperformanceIcon from '@assets/svg/profile_1.svg';
import OptimisingCostIcon from '@assets/svg/profile_2.svg';
import PeopleTeamsIcon from '@assets/svg/profile_3.svg';
import EverythingIcon from '@assets/svg/profile_4.svg';
import {
  DeviceAdoption,
  ExternalTeamsAccessDetails,
  GroupsTeams,
  MeetingAdoption,
  MeetingRooms,
  MsTeamsHealth,
  OrgGroupActivityUser,
  ServiceAdoption,
  TeamGroupsActivity,
  TeamGroupsAdoption,
  UserServiceAdoption,
} from '@hooks/adoption/types';
import {
  GroupsActivity,
  LocationsActivity,
  PeopleActivity,
} from '@hooks/productivity/types';
import {
  DeviceInventory,
  DeviceInventorySummary,
  LicensesInventory,
  LicensesInventoryAssignment,
  SharePointUsage,
} from '@hooks/utilisation/types';
import {
  ExternalAppResponse,
  ExternalTeamsAccess,
  ExternalTenantwideAppResponse,
  FailedLoginDataCountries,
  FailedLoginDataTypes,
  FailedLoginsData,
  MailboxForwardingData,
  OutdatedOSAccountsData,
  OutdatedBrowsersData,
  SuspiciousActivityAccounts,
  SuspiciousActivityEvents,
  OutdatedBrowserAccountsData,
  OutdatedOSData,
  MailboxDelegatesResponse,
  DelegateMailboxesResponse,
  CallForwardingDestinationsData,
  CallQueueData,
  ConfiguredTrunksResponse
} from '@hooks/security/types';
import { NotificationConf } from '@hooks/notifications/types';

// GENERAL //

export const WELCOME_TEXT = [
  'Hello',
  'Bonjour',
  'Hola',
  'Shalom',
  'Halløj',
  'Здраво',
  'Hallo',
  'Ciao',
  'Olá',
];

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SIDEBAR_LINKS = [
  { to: '/', label: 'Dashboard' },
  { to: '/insights-and-analytics/utilisation', label: 'Insights & analytics' },
  { to: '/reports', label: 'Reports' },
  // { to: '/help', label: 'Help, support & ideas' },
  { to: '/accounts/accounts', label: 'Accounts' },
  { to: '/settings', label: 'Settings' },
];

export const UI_DATE_FORMAT = 'DD/MM/YYYY';

export const APP_TITLE = 'CloudClevr';
export const DESKTOP_SCREEN_WIDTH = 1280;
export const MOBILE_SCREEN_WIDTH = 767;
export const DEFAULT_PAGE_SIZE = 20;
export const DEBOUNCE_TIME_MS = 500;
export const DEFAULT_ANIMATION_DURATION = 300;
export const ALL: VendorType = 'All';
export const VENDORS: VendorType[] = ['Microsoft', 'RingCentral', 'Mitel', 'Avaya'];
export const FREE_LICENSES = ['freeLicenses'];
export const AUTO_RENEW = ['Active', 'Inactive'];
export const PERSON_STATUS = ['Active', 'Inactive'];
export const ACCOUNT_TYPES = [
  'person',
  'meeting room',
  'shared mailbox',
  'unknown',
];
export const USERTYPE_FILTERS = ['internal', 'external'];

export const HTTP_CONFIG_API_URL = {
  analytics: 'http://localhost:8080',
  organization: 'http://localhost:8080',
};

export const RING_CENTRAL_CONSOLE =
  'https://developers.ringcentral.com/console/my-credentials/create?client_id=';

export const FILTER_SELECTED_KEY = {
  groups: 'groups',
  location: 'location',
};

export const LICENSE_TYPE = [
  'Microsoft E3',
  'Microsoft E5',
  'Microsoft F3',
  'RingCentral Premium',
  'RingCentral Standard',
  'RingCentral Ultimate',
];

export const LAST_DAYS_SORT_BY: SortData[] = [
  { label: 'Last 7 days', value: ['7'] },
  { label: 'Last 30 days', value: ['30'] },
  { label: 'Last 60 days', value: ['60'] },
  { label: 'Last 90 days', value: ['90'] },
];

export const VENDORS_SORT_BY: SortData[] = [
  { label: 'All Vendors', value: ['All'] },
  { label: 'Microsoft', value: ['Microsoft'] },
  { label: 'RingCentral', value: ['RingCentral'] },
];

export const DEFAULT_CHART_COLORS = [
  '#3F454B',
  '#00CF6C',
  '#25A8D1',
  '#B65AD7',
  '#FFB800',
  '#FF9ED3',
  '#6AD1CB',
  '#0089B5',
  '#FFD466',
  '#6AD1CB',
  '#00EC7B',
  '#A3DCE9',
  '#FDCBCD',
  '#B0141A',
  '#FFE4E6',
];

// INSIGHTS & ANALYTICS //

export const ANALYTICS_NAVIGATION_MAP = {
  licenseInventory: '/insights-and-analytics/utilisation/license-inventory',
  deviceInventory: '/insights-and-analytics/utilisation/device-inventory/summary',
  expiring: '/insights-and-analytics/utilisation/expiring-soon',
  expired: '/insights-and-analytics/utilisation/expired',
  assignment: '/insights-and-analytics/utilisation/unassigned-license',
  activity: '/insights-and-analytics/utilisation/inactive-license',
  overassigned: '/insights-and-analytics/utilisation/redundant-license',
  sharePointData: '/insights-and-analytics/utilisation/share-point-usage',
  groupsTeamsData: '/insights-and-analytics/utilisation/groups-and-teams',

  serviceUsage: '/insights-and-analytics/adoption/service-usage',
  serviceAdoption: '/insights-and-analytics/adoption/service-adoption',
  teamsGroupsAdoption:
    '/insights-and-analytics/adoption/teams-and-groups-adoption',
  meetingAdoption: '/insights-and-analytics/adoption/meeting-adoption',
  msTeamsHealth: '/insights-and-analytics/adoption/ms-teams-health',
  callQueues: '/insights-and-analytics/adoption/call-queues',
  activityData: '/insights-and-analytics/productivity/activity/people',
  secureScore: '/insights-and-analytics/assurance/microsoft-secure-score',
  mfaStatus: '/insights-and-analytics/assurance/mfa-status',
  signInEnabled: '/insights-and-analytics/assurance/sign-in-enabled',
  sharedMailboxes: '/insights-and-analytics/assurance/shared-mailboxes',
  deviceAdoptionData: '/insights-and-analytics/adoption/device-adoption',
  userServiceAdoption: '/insights-and-analytics/adoption/user-service-adoption',
  orgGroupActivityUser:
    '/insights-and-analytics/adoption/organisation-groups-activity-user',
  meetingsRoom: '/insights-and-analytics/adoption/meetings-room',
  externalTeamsAccess:
    '/insights-and-analytics/assurance/external-teams-access',
  teamsGroupsActivity:
    '/insights-and-analytics/adoption/teams-and-groups-activity',
  suspiciousActivity: '/insights-and-analytics/assurance/suspicious-activity',
  remoteAccessData: '/insights-and-analytics/assurance/remote-access-data',
  failedLoginCountriesData:
    '/insights-and-analytics/assurance/failed-login-data/countries',
  failedLoginTypesData:
    '/insights-and-analytics/assurance/failed-login-data/login-types',
  failedLoginAccounts:
    '/insights-and-analytics/assurance/failed-login-data/accounts',

  mailboxForwardingData: '/insights-and-analytics/assurance/mailbox-forwarding',
  outdatedOperatingSystems: '/insights-and-analytics/assurance/outdated-operating-systems',
  outdatedBrowsers: '/insights-and-analytics/assurance/outdated-browsers',
  delegatedMailboxData: '/insights-and-analytics/assurance/delegated-mailbox-data',
  callForwardingDestinations: '/insights-and-analytics/assurance/call-forwarding-destinations',
  configuredTrunksData: '/insights-and-analytics/utilisation/configured-trunks',
};

// Utilisation tab

export const SUBSCRIPTION_STATUS = [
  'StatusActive',
  'StatusExpired',
  'StatusDisabled',
];

export const ONLINE_STATUS = ['Online', 'Offline'];

export const SUBSCRIPTIONS_SORT_BY: SortData[] = [
  {
    label: 'Subscription type',
    value: ['subscriptionname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Expiry date',
    value: ['expirydate'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'Total licenses',
    value: ['totallicenses'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Assigned licenses',
    value: ['assigned'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const LICENSE_ASSIGNMENT_SORT_BY: SortData[] = [
  {
    label: 'License type',
    value: ['licensetype'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Assigned to',
    value: ['assignedto'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Assignment date',
    value: ['assignmentdate'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
  {
    label: 'Expiry date',
    value: ['expirydate'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'Last used',
    value: ['lastused'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const DEVICE_INVENTORY_SORT_BY: SortData[] = [
  { label: 'Device', value: ['device'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Vendor',
    value: ['vendor'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Assigned to',
    value: ['assignedto'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Type',
    value: ['type'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const DEVICE_INVENTORY_SUMMARY_SORT_BY: SortData[] = [
  {
    label: 'Vendor',
    value: ['vendor'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  { label: 'Device', value: ['device'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Type',
    value: ['type'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Total devices',
    value: ['total'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },

  {
    label: 'Assigned',
    value: ['assignedto'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];


export const SHARE_POINT_USAGE_SORT_BY: SortData[] = [
  {
    label: 'Site name',
    value: ['sitename'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Visits',
    value: ['visits'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Last activity',
    value: ['lastactivity'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'Storage',
    value: ['storage'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Pages',
    value: ['pages'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Files',
    value: ['files'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const SUBSCRIPTIONS_TABLE_COLUMNS: Column<LicensesInventory>[] = [
  { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
  { key: 'subscription', displayName: 'Subscription' },
  { key: 'status', displayName: 'Status', isComplexCell: true },
  { key: 'totalLicenses', displayName: 'Total licenses' },
  { key: 'assigned', displayName: 'Assigned' },
  // { key: 'autoRenew', displayName: 'Auto renew', isComplexCell: true }, //
  // { key: 'renewalPeriod', displayName: 'Renewal period' },
];

export const LICENSE_ASSIGNMENT_TABLE_COLUMNS: Column<LicensesInventoryAssignment>[] =
  [
    { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
    { key: 'licenseType', displayName: 'License type', isComplexCell: true },
    { key: 'assignedTo', displayName: 'Assigned to', isComplexCell: true },
    {
      key: 'assignmentDate',
      displayName: 'Assignment date',
      isComplexCell: true,
    },
    { key: 'expiryDate', displayName: 'Expiry date', isComplexCell: true },
    { key: 'lastUsed', displayName: 'Last used', isComplexCell: true },
  ];

export const DEVICE_INVENTORY_TABLE_COLUMNS: Column<DeviceInventory>[] = [
  { key: 'modelName', displayName: 'Device', isComplexCell: true },
  {
    key: 'assigneeDisplayName',
    displayName: 'Assigned to',
    isComplexCell: true,
  },
  { key: 'vendor', displayName: 'Vendor' },
  { key: 'type', displayName: 'Type' },
  { key: 'ipAddress', displayName: 'IP/MAC Address', isComplexCell: true, },
  { key: 'serialNumber', displayName: 'Serial number' },
];

export const DEVICE_INVENTORY_SUMMARY_TABLE_COLUMNS: Column<DeviceInventorySummary>[] = [
  { key: 'vendor', displayName: 'Vendor' },
  { key: 'modelName', displayName: 'Device', isComplexCell: true },
  { key: 'type', displayName: 'Type' },
  { key: 'total', displayName: 'Total devices' },
  { key: 'assigned', displayName: 'Assigned devices' },
];

export const SHARE_POINT_USAGE_COLUMNS: Column<SharePointUsage>[] = [
  { key: 'name', displayName: 'Site name', isComplexCell: true },
  {
    key: 'visits',
    displayName: 'Visits',
    isComplexCell: true,
  },
  {
    key: 'lastActivity',
    displayName: 'Last activity',
    isComplexCell: true,
  },
  { key: 'storage', displayName: 'Storage', isComplexCell: true },
  { key: 'pages', displayName: 'Pages', isComplexCell: true },
  { key: 'files', displayName: 'Files', isComplexCell: true },
];

// Licenses tab

export const RENEWAL_PERIOD = ['Annual', 'Monthly'];
export const ORDER_BY_VALUES = ['asc', 'desc'];

export const LICENSES_EXPIRING_BAR_LABELS = [
  'Expiring within 7 days:',
  'Expiring within 30 days:',
  'Expiring within 60 days:',
  'Expiring within 90 days:',
];

export const LICENSES_EXPIRED_SORT_BY: SortData[] = [
  {
    label: 'Vendor',
    value: ['vendor'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Subscription',
    value: ['subscriptionname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Status',
    value: ['status'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'Total licenses',
    value: ['totallicenses'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Assigned licenses',
    value: ['assigned'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const LICENSES_ACTIVITY_SORT_BY: SortData[] = [
  { label: 'Last 30 days', value: ['30'] },
  { label: 'Last 60 days', value: ['60'] },
  { label: 'Last 90 days', value: ['90'] },
];

export const LICENSES_EXPIRING_SORT_BY: SortData[] = [
  { label: 'Next 7 days', value: ['7'] },
  { label: 'Next 30 days', value: ['30'] },
  { label: 'Next 60 days', value: ['60'] },
  { label: 'Next 90 days', value: ['90'] },
];

export const LICENSES_SORT_BY: SortData[] = [
  {
    label: 'Expiry date',
    value: ['expirydate'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'License type',
    value: ['licensetype'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  // {
  //   label: 'Cost',
  //   value: ['cost'],
  //   orderByLabels: ['Lowest first', 'Highest first'],
  // },
  {
    label: 'Assigned to',
    value: ['assignedto'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Assignment date',
    value: ['assignmentdate'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
  {
    label: 'Last used',
    value: ['lastused'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const UNASSIGNED_LICENSES_SORT_BY: SortData[] = [
  {
    label: 'Vendor',
    value: ['vendor'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'License type',
    value: ['licensetype'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Expiry date',
    value: ['expirydate'],
    orderByLabels: ['Soonest first', 'Furthest first'],
  },
  {
    label: 'Unassigned count',
    value: ['unassignedcount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const LICENSES_TABLE_COLUMNS: Column<Licenses>[] = [
  { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
  { key: 'licenseType', displayName: 'License name', isComplexCell: true },
  { key: 'redundancyReason', displayName: 'Redundancy reason', isComplexCell: true },
  {
    key: 'assignmentDate',
    displayName: 'Assignment date',
    isComplexCell: true,
  },
  { key: 'expiryDate', displayName: 'Expiry date', isComplexCell: true },
  { key: 'assignedTo', displayName: 'Assigned to', isComplexCell: true },
];

export const INACTIVE_LICENSES_TABLE_COLUMNS: Column<Licenses>[] = [
  { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
  { key: 'licenseType', displayName: 'License type', isComplexCell: true },
  { key: 'assignedTo', displayName: 'Assigned to', isComplexCell: true },
  // { key: 'costPerMonth', displayName: 'Cost / month' },
  {
    key: 'assignmentDate',
    displayName: 'Assignment date',
    isComplexCell: true,
  },
  { key: 'expiryDate', displayName: 'Expiry date', isComplexCell: true },
  { key: 'lastUsed', displayName: 'Last used', isComplexCell: true },
];

export const UNASSIGNED_LICENSES_TABLE_COLUMNS: Column<Licenses>[] = [
  { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
  { key: 'licenseType', displayName: 'License type' },
  { key: 'expiryDate', displayName: 'Expiry date', isComplexCell: true },
  { key: 'unassigned', displayName: 'Unassigned count' },
];

export const LICENSES_EXPIRING_TABLE_COLUMNS: Column<LicensesExpiring>[] = [
  { key: 'vendor', displayName: 'Vendor' },
  { key: 'subscriptionName', displayName: 'Subscription' },
  { key: 'expiryStatus', displayName: 'Status', isComplexCell: true },
  { key: 'totalLicenses', displayName: 'Total licenses' },
  { key: 'assigned', displayName: 'Assigned' },
  // { key: 'autoRenew', displayName: 'Auto renew', isComplexCell: true },
  // { key: 'renewalPeriod', displayName: 'Renewal period' },
];

export const LICENSES_EXPIRED_TABLE_COLUMNS: Column<LicensesExpired>[] = [
  { key: 'subscription', displayName: 'Subscription' },
  { key: 'status', displayName: 'Status', isComplexCell: true },
  { key: 'totalLicenses', displayName: 'Total licenses' },
  { key: 'assigned', displayName: 'Assigned' },
  { key: 'autoRenew', displayName: 'Auto renew', isComplexCell: true },
  { key: 'renewalPeriod', displayName: 'Renewal period' },
];

// Adoption & productivity tab

export const SERVICE_USAGE_SORT_BY: SortData[] = [
  { label: 'Messaging', value: ['messaging'] },
  { label: 'Email', value: ['email'] },
  { label: 'Video calls', value: ['videocalls'] },
  { label: 'Phone calls', value: ['phonecalls'] },
  { label: 'File storage', value: ['filestorage'] },
];

export const LICENSES_USAGE_EMAILS_SENT: SortData[] = [
  { label: 'Messages', value: ['messages'] },
  { label: 'Emails', value: ['emails'] },
];
// 'name', 'messages', 'internalcall','videocall','externalcall'
export const SERVICE_ADOPTION_SORT_BY: SortData[] = [
  {
    label: 'Name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Messages sent',
    value: ['messages'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Internal phone calls made',
    value: ['internalcall'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'External phone calls made',
    value: ['externalcall'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Video use count',
    value: ['videocall'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const TEAMS_GROUPS_ACTIVITY_SORT_BY: SortData[] = [
  {
    label: 'Team name',
    value: ['teamname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Message count',
    value: ['messagecount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meeting count',
    value: ['meetingcount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const TEAMS_GROUPS_ADOPTION_SORT_BY: SortData[] = [
  {
    label: 'Name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  // {
  //   label: 'Total memberships',
  //   value: ['totalMembers'],
  //   orderByLabels: ['Lowest first', 'Highest first'],
  // },
  {
    label: 'MS Teams memberships',
    value: ['msteamsmemberships'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  // {
  //   label: 'RingCentral Groups memberships',
  //   value: ['rcMembers'],
  //   orderByLabels: ['Lowest first', 'Highest first'],
  // },
];

export const MS_TEAMS_HEALTH_SORT_BY: SortData[] = [
  {
    label: 'Team name',
    value: ['TeamName'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Member count',
    value: ['MemberCount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Message count',
    value: ['MessageCount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meeting count',
    value: ['MeetingCount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const GROUPS_TEAMS_SORT_BY: SortData[] = MS_TEAMS_HEALTH_SORT_BY;

export const EXTERNAL_TEAMS_ACCESS_DETAILS_SORT_BY: SortData[] =
  MS_TEAMS_HEALTH_SORT_BY;

export const MS_MEETING_ADOPTION_SORT_BY: SortData[] = [
  {
    label: 'Name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Total meeting activity',
    value: ['totalactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'MS Teams meeting activity',
    value: ['totalmsactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'RingCentral meeting activity',
    value: ['totalrcactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const DEVICE_ADOPTION_SORT_BY: SortData[] = [
  {
    label: 'Product',
    value: ['devicename'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Type',
    value: ['type'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Assigned to',
    value: ['assignedto'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const MEETING_ROOMS_SORT_BY: SortData[] = [
  {
    label: 'Room name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Hours booked',
    value: ['hoursbooked'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Last used',
    value: ['lastused'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const USER_SERVICE_ADOPTION_SORT_BY: SortData[] = [
  {
    label: 'Adoption rate',
    value: ['adoptionrate'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const ORG_GROUPS_ACTIVITY_USER_SORT_BY: SortData[] = [
  {
    label: 'Team name',
    value: ['teamname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Member count',
    value: ['membercount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Messages / user',
    value: ['messagesbyuser'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meetings / user',
    value: ['meetingsbyuser'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const CALLS_MADE_PEOPLE_SORT_BY: SortData[] = [
  {
    label: 'Name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Location',
    value: ['location'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Total activity',
    value: ['totalactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Emails sent',
    value: ['emails'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Messages sent',
    value: ['messages'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Calls made',
    value: ['calls'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meetings joined',
    value: ['meetings'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const CALLS_MADE_LOCATIONS_SORT_BY: SortData[] = [
  {
    label: 'Location name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Total activity',
    value: ['totalactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Emails sent',
    value: ['emails'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Messages sent',
    value: ['messages'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Calls made',
    value: ['calls'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meetings joined',
    value: ['meetings'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const CALLS_MADE_GROUPS_SORT_BY: SortData[] = [
  {
    label: 'Group name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Total activity',
    value: ['totalactivity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Emails sent',
    value: ['emails'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Messages sent',
    value: ['messages'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Calls made',
    value: ['calls'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Meetings joined',
    value: ['meetings'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const LICENSES_USAGE_SORT_BY: SortData[] = [
  {
    label: 'Messaging',
    value: ['messaging'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Emails',
    value: ['emails'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Video calls',
    value: ['videocalls'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Phone calls',
    value: ['phonecalls'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Files',
    value: ['files'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const LICENSES_USAGE_TABLE_COLUMNS: Column<LicensesUsage>[] = [
  { key: 'assignedTo', displayName: 'Name', isComplexCell: true },
  {
    key: 'totalMessagesSent',
    displayName: 'Messaging',
    displayNameDesc: 'Total sent',
    isComplexCell: true,
  },
  {
    key: 'totalEmailsSent',
    displayName: 'Emails',
    displayNameDesc: 'Total sent',
    isComplexCell: true,
  },
  {
    key: 'totalVideoCallsDuration',
    displayName: 'Video calls',
    displayNameDesc: 'Duration (hours)',
    isComplexCell: true,
  },
  {
    key: 'totalPhoneCallsDuration',
    displayName: 'Phone calls',
    displayNameDesc: 'Duration (hours)',
    isComplexCell: true,
  },
  {
    key: 'totalFilesAccessedOrModified',
    displayName: 'Files',
    displayNameDesc: 'Total written / read',
    isComplexCell: true,
  },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
];

export const SERVICE_ADOPTION_TABLE_COLUMNS: Column<ServiceAdoption>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'messagesSent', displayName: 'Messages sent', isComplexCell: true },
  {
    key: 'phoneCallsDuration',
    displayName: 'Internal phone call duration',
    isComplexCell: true,
  },
  {
    key: 'pstnCallsDuration',
    displayName: 'External phone call duration',
    isComplexCell: true,
  },
  {
    key: 'videoCallsDuration',
    displayName: 'Video call duration',
    isComplexCell: true,
  },
];

export const TEAMS_GROUPS_TABLE_COLUMNS: Column<TeamGroupsAdoption>[] = [
  { key: 'displayName', displayName: 'Name', isComplexCell: true },
  {
    key: 'msTeamsMembershipCount',
    displayName: 'MS Teams memberships',
    isComplexCell: true,
  },
  {
    key: 'lastCollaborated',
    displayName: 'Last collaborated',
    isComplexCell: true,
  },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  {
    key: 'groups',
    displayName: 'Group',
    isComplexCell: true,
  },
  // {
  //   key: 'rcMembers',
  //   displayName: 'RingCentral Groups memberships',
  //   isComplexCell: true,
  // },
];

export const TEAMS_GROUPS_ACTIVITY_TABLE_COLUMNS: Column<TeamGroupsActivity>[] =
  [
    { key: 'teamName', displayName: 'Team / group name', isComplexCell: true },
    {
      key: 'status',
      displayName: 'Status',
      isComplexCell: true,
    },
    {
      key: 'createAt',
      displayName: 'Created date',
      isComplexCell: true,
    },
    {
      key: 'owners',
      displayName: 'Owner',
      isComplexCell: true,
    },
    {
      key: 'messageCount',
      displayName: 'Message count',
      isComplexCell: true,
    },
    {
      key: 'meetingCount',
      displayName: 'Meeting count',
      isComplexCell: true,
    },
  ];

export const MS_TEAMS_TABLE_COLUMNS: Column<MsTeamsHealth>[] = [
  { key: 'teamName', displayName: 'Team name' },
  {
    key: 'memberCount',
    displayName: 'Member count',
    isComplexCell: true,
  },
  {
    key: 'owners',
    displayName: 'Owner',
    isComplexCell: true,
  },
  {
    key: 'messageCount',
    displayName: 'Message count',
    isComplexCell: true,
  },
  {
    key: 'meetingCount',
    displayName: 'Meeting count',
    isComplexCell: true,
  },
  { key: 'lastUsed', displayName: 'Last used', isComplexCell: true },
];

export const GROUPS_TEAMS_TABLE_COLUMNS: Column<GroupsTeams>[] =
  MS_TEAMS_TABLE_COLUMNS;

export const EXTERNAL_TEAMS_ACCESS_DETAILS_TABLE_COLUMNS: Column<ExternalTeamsAccessDetails>[] =
  MS_TEAMS_TABLE_COLUMNS;

export const MEETING_ADOPTION_TABLE_COLUMNS: Column<MeetingAdoption>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  {
    key: 'totalMsMeetingsOrganized',
    displayName: 'MS Teams meetings set up',
    isComplexCell: true,
  },
  {
    key: 'totalMsMeetingsJoined',
    displayName: 'MS Teams meetings joined',
    isComplexCell: true,
  },
  {
    key: 'totalRcMeetingsOrganized',
    displayName: 'RingCentral meetings set up',
    isComplexCell: true,
  },
  {
    key: 'totalRcMeetingsJoined',
    displayName: 'RingCentral meetings joined',
    isComplexCell: true,
  },
];

export const MEETING_ROOMS_TABLE_COLUMNS: Column<MeetingRooms>[] = [
  { key: 'name', displayName: 'Room name', isComplexCell: true },
  {
    key: 'minutesBooked',
    displayName: 'Hours booked',
    isComplexCell: true,
  },
  {
    key: 'videoMinutesUsed',
    displayName: 'Video hours used',
    isComplexCell: true,
  },
  {
    key: 'lastActivity',
    displayName: 'Last used',
    isComplexCell: true,
  },
];

export const USER_SERVICE_ADOPTION_TABLE_COLUMNS: Column<UserServiceAdoption>[] =
  [
    { key: 'serviceName', displayName: 'Service', isComplexCell: true },
    {
      key: 'adoptionRate',
      displayName: 'Adoption Rate',
      isComplexCell: true,
    },
    {
      key: 'uses',
      displayName: 'Total uses',
      isComplexCell: true,
    },
    {
      key: 'vendor',
      displayName: '',
      isComplexCell: true,
      isMobileLabel: true,
    },
  ];

export const ORG_GROUPS_ACTIVITY_USER_TABLE_COLUMNS: Column<OrgGroupActivityUser>[] =
  [
    { key: 'teamName', displayName: 'Team name', isComplexCell: true },
    {
      key: 'memberCount',
      displayName: 'Members',
      isComplexCell: true,
    },
    {
      key: 'messageByUserCount',
      displayName: 'Messages / user',
      isComplexCell: true,
    },
    {
      key: 'meetingByUserCount',
      displayName: 'Meetings / user',
      isComplexCell: true,
    },
  ];

export const PERSON_LICENSES_TABLE_COLUMNS: Column<PersonLicenses>[] = [
  { key: 'licenseType', displayName: 'License type', isComplexCell: true },
  {
    key: 'assignmentDate',
    displayName: 'Assignment date',
    isComplexCell: true,
  },
  {
    key: 'expiryDate',
    displayName: 'Expiry date',
    isComplexCell: true,
  },
  {
    key: 'lastUsed',
    displayName: 'Last used',
    isComplexCell: true,
  },
];

export const ACTIVITY_PEOPLE_TABLE_COLUMNS: Column<PeopleActivity>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  {
    key: 'groups',
    displayName: 'Group',
    isComplexCell: true,
  },
  { key: 'emailsSent', displayName: 'Emails sent', isComplexCell: true },
  { key: 'messagesSent', displayName: 'Messages sent', isComplexCell: true },
  { key: 'callsMade', displayName: 'Calls made', isComplexCell: true },
  {
    key: 'meetingsJoined',
    displayName: 'Meetings joined',
    isComplexCell: true,
  },
];

export const ACTIVITY_LOCATIONS_TABLE_COLUMNS: Column<LocationsActivity>[] = [
  { key: 'locationName', displayName: 'Location name', isComplexCell: true },
  { key: 'emailsSent', displayName: 'Emails sent', isComplexCell: true },
  { key: 'messagesSent', displayName: 'Messages sent', isComplexCell: true },
  { key: 'callsMade', displayName: 'Calls made', isComplexCell: true },
  {
    key: 'meetingsJoined',
    displayName: 'Meeting joined',
    isComplexCell: true,
  },
];

export const ACTIVITY_GROUPS_TABLE_COLUMNS: Column<GroupsActivity>[] = [
  { key: 'groupName', displayName: 'Group name', isComplexCell: true },
  { key: 'emailsSent', displayName: 'Emails sent', isComplexCell: true },
  { key: 'messagesSent', displayName: 'Messages sent', isComplexCell: true },
  { key: 'callsMade', displayName: 'Calls made', isComplexCell: true },
  {
    key: 'meetingsJoined',
    displayName: 'Meetings joined',
    isComplexCell: true,
  },
];

export const DEVICE_ADOPTION_TABLE_COLUMNS: Column<DeviceAdoption>[] = [
  {
    key: 'vendor',
    displayName: 'Vendor',
    isComplexCell: true,
  },
  {
    key: 'modelName',
    displayName: 'Product',
    isComplexCell: true,
  },
  {
    key: 'assigneeDisplayName',
    displayName: 'Assigned to',
    isComplexCell: true,
  },
  // {
  //   key: 'vendor',
  //   displayName: 'Vendor',
  // },
  { key: 'type', displayName: 'Type' },
  {
    key: 'ipAddress',
    displayName: 'IP/MAC Address',
    isComplexCell: true,
  },
  {
    key: 'serialNumber',
    displayName: 'Serial number',
  },
  {
    key: 'initialDate',
    displayName: 'Install date',
    isComplexCell: true,
  },
  {
    key: 'connectionStatus',
    displayName: 'Connection status',
    isComplexCell: true,
  },
];

// Security tab

export const USER_TYPE = ['Admin', 'Standard'];

export const SECURE_SCORE_SORT_BY: SortData[] = [
  {
    label: 'Improvement action',
    value: ['title'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Category',
    value: ['category'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Score impact',
    value: ['scoreimpact'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const SUSPICIOUS_ACTIVITY_EVENTS_SORT_BY: SortData[] = [
  {
    label: 'Date / time',
    value: ['datetime'],
    orderByLabels: ['Oldest first', 'Most recent first'],
  },
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Browser',
    value: ['browser'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Country',
    value: ['country'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'ASN name',
    value: ['asnname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const SUSPICIOUS_ACTIVITY_ACCOUNTS_SORT_BY: SortData[] = [
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Event count',
    value: ['eventcount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Date / time',
    value: ['datetime'],
    orderByLabels: ['Oldest first', 'Most recent first'],
  },
];

export const EXTERNAL_APPS_SORT_BY: SortData[] = [
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'App name',
    value: ['appname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Publisher',
    value: ['publisher'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Access',
    value: ['access'],
    orderByLabels: [
      'Lowest intrusiveness first',
      'Highest intrusiveness first',
    ],
  },
];

export const EXTERNAL_TENANTWIDE_APPS_SORT_BY: SortData[] = [
  {
    label: 'App name',
    value: ['appname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Publisher',
    value: ['publisherVerification'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Access',
    value: ['permissionLevel'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Access granted',
    value: ['accessGranted'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const NO_LICENCES_SORT_BY: SortData[] = [
  { label: 'Name', value: ['name'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'User since',
    value: ['usersince'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
  {
    label: 'Last use',
    value: ['lastuse'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const FAILED_LOGIN_COUNTRIES_SORT_BY: SortData[] = [
  { label: 'Country', value: ['country'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Failed logins',
    value: ['failedlogins'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Successful logins',
    value: ['successfullogins'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const FAILED_LOGIN_TYPES_SORT_BY: SortData[] = [
  { label: 'Type', value: ['types'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Failed logins',
    value: ['failedlogins'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Successful logins',
    value: ['successfullogins'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const FAILED_LOGINS_SORT_BY: SortData[] = [
  { label: 'Account', value: ['fullname'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Failed logins',
    value: ['numberoffailedlogins'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const SHARED_MAILBOXES_SORT_BY: SortData[] = [
  {
    label: 'Mailbox name',
    value: ['mailboxname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Emails received',
    value: ['emailsreceived'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Emails sent',
    value: ['emailssent'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const MAILBOX_FORWARDING_SORT_BY: SortData[] = [
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Forwarding address',
    value: ['forwardingaddress'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const CALL_FORWARDING_DESTINATIONS_SORT_BY: SortData[] = [
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const OUTDATED_BROWSERS_SORT_BY: SortData[] = [
  {
    label: 'Browser',
    value: ['browser'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Login count',
    value: ['logincount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Logins: External users',
    value: ['loginexternal'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Logins: Internal users',
    value: ['logininternal'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const OUTDATED_BROWSER_ACCOUNTS_SORT_BY: SortData[] = [
  {
    label: 'Account',
    value: ['account'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Browser',
    value: ['browser'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Login count',
    value: ['logincount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];


export const MFA_STATUS_SORT_BY: SortData[] = [
  {
    label: 'MFA status',
    value: ['mfastatus'],
    orderByLabels: ['Active first', 'Inactive first'],
  },
  {
    label: 'Name',
    value: ['name'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
];

export const EXTERNAL_TEAMS_ACCESS_SORT_BY: SortData[] = [
  {
    label: 'Email address',
    value: ['email'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Teams accessible',
    value: ['teamname'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Last accessed',
    value: ['collaboration'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const MFA_STATUS_USER_TYPE_SORT_BY: SortData[] = [
  { label: 'All users', value: ['All'] },
  { label: 'Admin users', value: ['Admin'] },
  { label: 'Non-admin users', value: ['Standard'] },
];

export const OUTDATED_OS_ACCOUNTS_SORT_BY: SortData[] = [
  { label: 'Account', value: ['account'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Operating system',
    value: ['operatingsystem'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Login count', value: ['logincount'], orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const OUTDATED_OS_DATA_SORT_BY: SortData[] = [
  {
    label: 'Operating system',
    value: ['operatingsystem'],
    orderByLabels: ['A-Z', 'Z-A'],
  },
  {
    label: 'Login count', value: ['logincount'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Logins: internal users', value: ['logininternal'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
  {
    label: 'Logins: external users', value: ['loginexternal'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },

];

export const MAILBOX_DELEGATES_SORT_BY: SortData[] = [
  { label: 'Account', value: ['account'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Mailboxes accessed through delegation',
    value: ['delegation'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const DELEGATED_MAILBOX_SORT_BY: SortData[] = [
  { label: 'Mailbox ID', value: ['mailboxemail'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Delegates',
    value: ['delegation'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const CALL_QUEUES_SORT_BY: SortData[] = [
  { label: 'Name', value: ['name'], orderByLabels: ['A-Z', 'Z-A'] },
];

export const CONFIGURED_TRUNKS_SORT_BY: SortData[] = [
  { label: 'Trunk group', value: ['trunkname'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'Capacity',
    value: ['capacity'],
    orderByLabels: ['Lowest first', 'Highest first'],
  },
];

export const SECURE_SCORE_TABLE_COLUMNS: Column<SecureScore>[] = [
  {
    key: 'title',
    displayName: 'Improvement action', isComplexCell: true
  },
  { key: 'scoreImpact', displayName: 'Score impact', isComplexCell: true },
  { key: 'category', displayName: 'Category' },
  { key: 'isCompleted', displayName: 'Status', isComplexCell: true },
];

export const MAILBOXES_WITH_MOST_DELEGATES_TABLE_COLUMNS: Column<MostDelegatesMailbox>[] = [
  { key: 'mailboxId', displayName: 'Mailbox ID'},
  { key: 'delegatesCount', displayName: 'Delegates count' },
];


export const MFA_STATUS_TABLE_COLUMNS: Column<MfaStatus>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'userType', displayName: 'User Type' },
  { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
  { key: 'mfaStatus', displayName: 'MFA status', isComplexCell: true },
  {
    key: 'passwordExpiry',
    displayName: 'Password expiry',
    isComplexCell: true,
  },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
];

export const NO_LICENCES_TABLE_COLUMNS: Column<NoLicences>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'lastUse', displayName: 'Last use', isComplexCell: true },
  { key: 'userSince', displayName: 'User since', isComplexCell: true },
];

export const FAILED_LOGIN_COUNTRIES_TABLE_COLUMNS: Column<FailedLoginDataCountries>[] =
  [
    { key: 'countryName', displayName: 'Country' },
    { key: 'numberOfFailedLogins', displayName: 'Failed logins' },
    { key: 'numberOfSuccessfulLogins', displayName: 'Successful logins' },
  ];

export const FAILED_LOGIN_TYPES_TABLE_COLUMNS: Column<FailedLoginDataTypes>[] =
  [
    { key: 'loginType', displayName: 'Login type' },
    { key: 'numberOfFailedLogins', displayName: 'Failed logins' },
    { key: 'numberOfSuccessfulLogins', displayName: 'Successful logins' },
  ];

export const FAILED_LOGIN_ACCOUNTS_TABLE_COLUMNS: Column<FailedLoginsData>[] = [
  { key: 'fullName', displayName: 'Account', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'status', displayName: 'Account status', isComplexCell: true },
  { key: 'mfaStatus', displayName: 'MFA status', isComplexCell: true },
  { key: 'country', displayName: 'Source country', isComplexCell: true },
  { key: 'numberOfFailedLogins', displayName: 'Failed logins' },
];

export const FAILED_LOGIN_ACCOUNTS_TABLE_COUNTRY_COLUMNS: Column<{
  name: string;
  loginAttempts: string;
}>[] = [
    { key: 'name', displayName: 'Country', isComplexCell: true },
    { key: 'loginAttempts', displayName: 'Login attempts', isComplexCell: true },
  ];

export const SUSPICIOUS_ACTIVITY_EVENTS_TABLE_COLUMNS: Column<SuspiciousActivityEvents>[] =
  [
    { key: 'eventType', displayName: 'Event type' },
    { key: 'dateTime', displayName: 'Date / time', isComplexCell: true },
    { key: 'ipAddress', displayName: 'IP address', isComplexCell: true },
    { key: 'fullName', displayName: 'Account', isComplexCell: true },
    { key: 'browser', displayName: 'Browser' },
    { key: 'country', displayName: 'Country' },
    { key: 'asnName', displayName: 'ASN name' },
  ];

export const SUSPICIOUS_ACTIVITY_ACCOUNTS_TABLE_COLUMNS: Column<SuspiciousActivityAccounts>[] =
  [
    { key: 'fullName', displayName: 'Account', isComplexCell: true },
    { key: 'eventTypes', displayName: 'Event type', isComplexCell: true },
    { key: 'eventCount', displayName: 'Event count' },
    { key: 'dateTime', displayName: 'Latest event date / time', isComplexCell: true },
  ];

export const EXTERNAL_APPS_TABLE_COLUMNS: Column<ExternalAppResponse>[] = [
  { key: 'userFullName', displayName: 'Account', isComplexCell: true },
  { key: 'applicationName', displayName: 'App name', isComplexCell: true },
  { key: 'publisherName', displayName: 'Publisher', isComplexCell: true },
  { key: 'permissionLevel', displayName: 'Access', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
];

export const EXTERNAL_TENANTWIDE_APPS_TABLE_COLUMNS: Column<ExternalTenantwideAppResponse>[] =
  [
    { key: 'applicationName', displayName: 'App name', isComplexCell: true },
    { key: 'publisherName', displayName: 'Publisher', isComplexCell: true },
    { key: 'permissionLevel', displayName: 'Access', isComplexCell: true },
    {
      key: 'accessGranted',
      displayName: 'Access Granted',
      isComplexCell: true,
    },
  ];

export const SHARED_MAILBOXES_TABLE_COLUMNS: Column<SharedMailboxes>[] = [
  { key: 'name', displayName: 'Mailbox name', isComplexCell: true },
  {
    key: 'emailsReceived',
    displayName: 'Emails received',
    isComplexCell: true,
  },
  { key: 'emailsSent', displayName: 'Emails sent', isComplexCell: true },
  { key: 'status', displayName: 'Sign-in status', isComplexCell: true },
];

export const MAILBOX_FORWARDING_TABLE_COLUMNS: Column<MailboxForwardingData>[] =
  [
    { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
    {
      key: 'locationName',
      displayName: 'Location',
      isComplexCell: true,
    },
    { key: 'groups', displayName: 'Group', isComplexCell: true },
    { key: 'accountType', displayName: 'Type', isComplexCell: true },
    {
      key: 'forwardToFullName',
      displayName: 'Forwarding address',
      isComplexCell: true,
    },
    {
      key: 'forwardAccountType',
      displayName: 'Forward account type',
      isComplexCell: true,
    },
  ];

export const CALL_FORWARDING_DESTINATIONS_TABLE_COLUMNS: Column<CallForwardingDestinationsData>[] =
  [
    { key: 'vendor', displayName: 'Vendor', isComplexCell: true },
    { key: 'extensionNumber', displayName: 'Account', isComplexCell: true },
    { key: 'always', displayName: 'Always', isComplexCell: true },
    { key: 'busyInt', displayName: 'Busy int', isComplexCell: true },
    { key: 'busyExt', displayName: 'Busy ext', isComplexCell: true },
    { key: 'noAnswerInt', displayName: 'No ans int', isComplexCell: true },
    { key: 'noAnswerExt', displayName: 'No ans ext', isComplexCell: true },
  ];

export const OUTDATED_BROWSER_ACCOUNTS_TABLE_COLUMNS: Column<OutdatedBrowserAccountsData>[] = [
  { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
  {
    key: 'locationName',
    displayName: 'Location',
    isComplexCell: true,
  },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'accountType', displayName: 'User type', isComplexCell: true },
  {
    key: 'fullVersionName',
    displayName: 'Browser',
  },
  {
    key: 'totalLoginCount',
    displayName: 'Login count',
  },
];

export const OUTDATED_BROWSERS_TABLE_COLUMNS: Column<OutdatedBrowsersData>[] = [
  {
    key: 'fullVersionName',
    displayName: 'Browser',
  },
  {
    key: 'totalLoginCount',
    displayName: 'Login count',
  },
  {
    key: 'internalUsersLoginCount',
    displayName: 'Logins: Internal users',
  },
  {
    key: 'externalUsersLoginCount',
    displayName: 'Logins: External users',
  },
];


export const EXTERNAL_TEAMS_ACCESS_TABLE_COLUMNS: Column<ExternalTeamsAccess>[] =
  [
    { key: 'email', displayName: 'Email address', isComplexCell: true },
    {
      key: 'teamDisplayName',
      displayName: 'Teams accessible',
      isComplexCell: true,
    },
    {
      key: 'hasntCollaboratedDays',
      displayName: 'Last collaborated',
      isComplexCell: true,
    },
  ];

  export const CALL_QUEUES_TABLE_COLUMNS: Column<CallQueueData>[] = [
    { key: 'vendor', displayName: 'Vendor' },
    { key: 'name', displayName: 'Name', isComplexCell: true },
    { key: 'number', displayName: 'Number' },
    { key: 'mode', displayName: 'Mode' },
  ];

// REPORTS //

// 0 - Service review
// 1 - Security recommendations
export const REPORTS_TYPES = ['0', '1'];

export const REPORTS_STATUS = ['Unread', 'Read'];

export const REPORTS_SORT_BY: SortData[] = [
  {
    label: 'Date',
    value: ['CreatedAt'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const REPORTS_TABLE_COLUMNS: Column<Reports>[] = [
  { key: 'name', displayName: 'Name', isComplexCell: true },
  { key: 'date', displayName: 'Date', isComplexCell: true },
  { key: 'actions', displayName: '', isComplexCell: true },
];

export const REPORTS_FAILED_LOGINS_TABLE_COLUMNS: Column<FailedLoginsData>[] = [
  { key: 'fullName', displayName: 'Account', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'status', displayName: 'Account status', isComplexCell: true },
  { key: 'mfaStatus', displayName: 'MFA status', isComplexCell: true },
  { key: 'country', displayName: 'Source country', isComplexCell: true },
  { key: 'numberOfFailedLogins', displayName: 'Failed logins' },
];

export const REPORTS_OUTDATED_BROWSER_TABLE_COLUMN: Column<any>[] = [
  { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'accountType', displayName: 'User type', isComplexCell: true },
  { key: 'name', displayName: 'Browser', isComplexCell: true },
  { key: 'loginCounts', displayName: 'Login count', isComplexCell: true },
];

// ACCOUNTS //

export const MAX_SUBGROUP_LEVEL = 5;

export const PEOPLE_SORT_BY: SortData[] = [
  { label: 'Name', value: ['fullname'], orderByLabels: ['A-Z', 'Z-A'] },
  { label: ' Account type', value: ['type'], orderByLabels: ['A-Z', 'Z-A'] },
  {
    label: 'User since',
    value: ['usersince'],
    orderByLabels: ['Oldest first', 'Newest first'],
  },
];

export const PEOPLE_FILTER_BY: FilterData[] = [
  {
    label: 'Status',
    name: 'status',
    singleSelect: true,
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Inactive', value: 'Inactive' },
    ],
  },
];

export const PEOPLE_TABLE_COLUMNS: Column<Person>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'type', displayName: 'Type', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'location', displayName: 'Location', isComplexCell: true },
  { key: 'status', displayName: 'Status', isComplexCell: true },
  { key: 'userSince', displayName: 'User Since', isComplexCell: true },
];

export const LOCATION_TABLE_COLUMNS: Column<Person>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'location', displayName: 'Location', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'status', displayName: 'Status', isComplexCell: true },
  { key: 'userSince', displayName: 'User Since', isComplexCell: true },
];

export const GROUP_TABLE_COLUMNS: Column<Person>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'location', displayName: 'Location', isComplexCell: true },
  { key: 'status', displayName: 'Status', isComplexCell: true },
  { key: 'userSince', displayName: 'User Since', isComplexCell: true },
];

// HUB SETTINGS //

export const HUB_USERS_TABLE_COLUMNS: Column<UserColumn>[] = [
  { key: 'fullName', displayName: 'Name', isComplexCell: true },
  { key: 'role', displayName: 'Role' },
  { key: 'phone', displayName: 'Phone', isComplexCell: true },
  { key: 'isEnabled', displayName: 'Account status', isComplexCell: true },
  { key: 'tableDropdown', displayName: '' },
];

export const OUTDATED_OS_ACCOUNTS_TABLE_COLUMNS: Column<OutdatedOSAccountsData>[] = [
  { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
  { key: 'locationName', displayName: 'Location', isComplexCell: true },
  { key: 'accountType', displayName: 'User type', isComplexCell: true },
  { key: 'fullVersionName', displayName: 'Operating system', isComplexCell: true },
  { key: 'totalLoginCount', displayName: 'Login count' },
];

export const OUTDATED_OS_TABLE_COLUMNS: Column<OutdatedOSData>[] = [
  { key: 'fullVersionName', displayName: 'Operating system', isComplexCell: true },
  { key: 'totalLoginCount', displayName: 'Login count' },
  { key: 'externalUsersLoginCount', displayName: 'Logins: external users' },
  { key: 'internalUsersLoginCount', displayName: 'Logins: Internal users' },
];

export const MAILBOX_DELEGATES_TABLE_COLUMNS: Column<MailboxDelegatesResponse>[] = [
  { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
  { key: 'accessibleMailboxes', displayName: 'Accessible mailboxes', isComplexCell: true },
  { key: 'locationName', displayName: 'Location' },
  { key: 'groups', displayName: 'Group', isComplexCell: true },
];

export const DELEGATE_MAILBOXES_TABLE_COLUMNS: Column<DelegateMailboxesResponse>[] = [
  { key: 'mailboxEmail', displayName: 'Mailbox ID', isComplexCell: true },
  { key: 'delegations', displayName: 'Delegates', isComplexCell: true },
];

export const CONFIGURED_TRUNKS_TABLE_COLUMNS: Column<ConfiguredTrunksResponse>[] = [
  { key: 'vendor', displayName: 'Vendor' },
  { key: 'trunkName', displayName: 'Trunk group' },
  { key: 'callingPartyNumber', displayName: 'Number' },
  { key: 'type', displayName: 'Type' },
  { key: 'capacity', displayName: 'Capacity' },
];

export const REPORT_SUSPICIOUS_ACTIVITY_TABLE_COLUMNS: Column<any>[] =
  [
    { key: 'fullName', displayName: 'Account', isComplexCell: true },
    { key: 'eventCounts', displayName: 'Event count', isComplexCell: true },
    { key: 'latestEventDateTime', displayName: 'Latest event date / time', isComplexCell: true },
  ];

  export const REPORTS_OUTDATED_OS_TABLE_COLUMNS: Column<any>[] = [
    { key: 'accountFullName', displayName: 'Account', isComplexCell: true },
    { key: 'locationName', displayName: 'Location', isComplexCell: true },
    { key: 'groups', displayName: 'Group', isComplexCell: true },
    { key: 'accountType', displayName: 'User type', isComplexCell: true },
    { key: 'name', displayName: 'Operating system', isComplexCell: true },
    { key: 'loginCounts', displayName: 'Login count' },
  ];
    
export const MAILBOX_DELEGATES_MODAL_COLUMNS: Column<{
  email: string;
}>[] = [
    { key: 'email', displayName: '', isComplexCell: true },
  ];

export enum DashboardTiles {
  LicensesExpiringSoon = 0,
  LicensesExpiringSoonSnapshot = 1,
  LicensesExpiringSoonVendorSplit = 2,
  UsersImpactedByLicenseExpiry = 3,
  ExpiredAndDisabledLicenses = 4,
  ExpiredAndDisabledLicensesSnapshot = 5,
  ExpiredAndDisabledLicensesServiceType = 6,
  LicenseAssignment = 7,
  UnassignedLicensesTrendOverTime = 8,
  UnassignedLicensesSnapshot = 9,
  LicenseActivity = 10,
  InactiveLicensesTrendOverTime = 11,
  InactiveLicensesSnapshot = 12,
  OverassignedLicenses = 13,
  OverassignedLicensesTrendOverTime = 14,
  OverassignedLicensesSnapshot = 15,
  ServiceUsageTrendOverTime = 16,
  ServiceUsageTrendOverTimeSnapshot = 17,
  ServiceUsageAvgTimeSpentOnCalls = 18,
  ServiceUsageMessagesEmailsSent = 19,
  MicrosoftMFAStatus = 20,
  // MicrosoftMFAStatusSnapshot = 21,
  UsersWithSignInEnabledButNoLicenses = 22,
  // UsersWithSignInEnabledButNoLicensesSnapshot = 23,
  // ActivityByUsersWithoutLicenses = 24,
  SharedMailboxesWithSignInEnabled = 25,
  // SharedMailboxesWithSignInEnabledSnapshot = 26,
  SignInsToSharedMailboxes = 27,
  LicenseInventory = 28,
  HardwareInventory = 29,
  ServiceAdoptionByUser = 30,
  MessagingAdoption = 31,
  InternalPhoneAdoption = 32,
  ExternalPhoneAdoption = 33,
  VideoAdoption = 34,
  TeamsAndGroupsAdoption = 35,
  TeamsAndGroupsAdoptionSnapshot = 36,
  TeamsAndGroupsAdoptionOverTime = 37,
  MsTeamsHealth = 41,
  TeamsHealth = 42,
  TotalMessagesSent = 43,
  TotalMeetingsHeld = 44,
  CallsMade = 45,
  ActivityPerDayPeople = 46,
  TotalActivityPeople = 47,
  ActivityPerDayLocations = 48,
  TotalActivityLocations = 49,
  ActivityPerDayGroups = 50,
  TotalActivityGroups = 51,
  EmailsSent = 52,
  MessagesSent = 53,
  MeetingsSent = 54,
  ActivityPerDay = 55,
  SharePointUsage = 56,
  SharePointUsageTrendOverTime = 57,
  ActivityPerDayChart = 58,
  ActivityTotalsNumber = 59,
  TeamsAndGroupsCollaboration = 60,
  MeetingAdoption = 61,
  MeetingAdoptionSnapshot = 62,
  DailyMeetingAttendanceTrend = 63,
  UserServiceAdoptionSnapshot = 64,
  UserServiceAdoptionTrend = 65,
  OrgGroupActivityUser = 66,
  DeviceUsage = 67,
  OrgGroupActivityUserSnapshot = 68,
  OrgGroupActivityUserMeetingsSnapshot = 69,
  OrgGroupActivityUserMessagesSnapshot = 70,
  LicenseInventoryChangesOverTime = 71,
  InactiveRooms = 72,
  MeetingRoomVideo = 73,
  SecureScoreChangesOverTime = 74,
  SecureScoreComparison = 75,
  SecureScoreIdentity = 76,
  SecureScoreDevice = 77,
  SecureScoreApps = 78,
  SecureScoreInfrastructure = 79,
  MeetingRoomDeviceUsage = 80,
  DeviceAdoption = 81,
  MeetingRoomsTrend = 82,
  MeetingRoomsHoursBooked = 83,
  ExternalResourcesAccess = 84,
  MeetingRoomVideoSnapshot = 85,
  InactiveRoomsSnapshot = 86,
  GroupsTeamsInventory = 87,
  GroupsTeamsInventorySnapshot = 88,
  MSTeamsAdoption = 89,
  MSTeamsCollaboration = 90,
  ExternalTeamsAccess = 91,
  ExternalTeamsAccessSnapshot = 92,
  TeamsWithExternalAccess = 93,
  MicrosoftSecureScore = 94,
  TeamsAndGroupsActivitySnapshot = 95,
  ActiveSharePointSites = 96,
  TotalSharePointSites = 97,
  SuspiciousActivityTrend = 98,
  ExternalAppsMSData = 99,
  FailedLoginCountries = 100,
  FailedLoginTypes = 101,
  FailedLoginAccounts = 102,
  MailboxesForwardingRules = 103,
  OutDatedOperatingSystemsActivity = 104,
  OutdatedBrowserActivity = 105,
  DelegatedMailboxData = 106,
  ConfiguredTrunks = 107,
  CallQueues = 108,
  CallForwardingDestinations = 109,
  Table = 110,
  SharedMailboxesSignInInfo = 111,
  AutoForwardingByUserTypeMailboxes = 112,
  MailboxesWithDelegatedAccess = 113,
  AccountsUsingDelegationToAccessMailbox = 114,
  AutoForwardingRulesAndDelegationInfo = 115,
  UsersWithMFAEnforced = 116,
  FailedLoginActivityInfo = 117,
  SuspiciousActivityInfo = 118,
  SuspiciousActivityLineChart = 119,
  SuspiciousActivityTotalEvents = 120,
  SuspiciousActivityType = 121,
  SuspiciousActivityMap = 122,
  OutdatedSystemsLogins = 124,
  OutdatedSystemsUsed = 125,
  UsersWithOutdatedSystems = 126,
  OutdatedSystemsInfo = 128,
  OutdatedBrowserLogins = 129,
  OutdatedBrowsersUsed = 130,
  UsersWithOutdatedBrowsers = 131,
  OutdatedBrowsersInfo = 133,
}

export const SecurityDashboardTiles = [
  DashboardTiles.MicrosoftMFAStatus,
  DashboardTiles.MicrosoftSecureScore,
  DashboardTiles.TeamsWithExternalAccess,
  DashboardTiles.ExternalTeamsAccessSnapshot,
  DashboardTiles.SecureScoreInfrastructure,
  DashboardTiles.SecureScoreApps,
  DashboardTiles.SecureScoreDevice,
  DashboardTiles.SecureScoreIdentity,
  DashboardTiles.SecureScoreComparison,
  DashboardTiles.SecureScoreChangesOverTime,
  DashboardTiles.SharedMailboxesWithSignInEnabled,
  DashboardTiles.UsersWithSignInEnabledButNoLicenses,
  DashboardTiles.ExternalTeamsAccess,
  DashboardTiles.FailedLoginCountries,
  DashboardTiles.FailedLoginTypes,
  DashboardTiles.FailedLoginAccounts,
  DashboardTiles.MailboxesForwardingRules,
  DashboardTiles.OutDatedOperatingSystemsActivity,
  DashboardTiles.OutdatedBrowserActivity,
];

export const EnhancedSecurityInsightsTiles = [
  DashboardTiles.SuspiciousActivityTrend,
  DashboardTiles.ExternalAppsMSData,
  DashboardTiles.FailedLoginCountries,
  DashboardTiles.FailedLoginTypes,
  DashboardTiles.FailedLoginAccounts,
  DashboardTiles.MailboxesForwardingRules,
  DashboardTiles.OutDatedOperatingSystemsActivity,
  DashboardTiles.OutdatedBrowserActivity,
];

export const TraditionalCommsInsightsTiles = [
  DashboardTiles.ConfiguredTrunks,
  DashboardTiles.CallQueues,
  DashboardTiles.CallForwardingDestinations
];

export const profiles: InsightProfile[] = [
  {
    value: 1,
    name: 'Licenses and performance',
    description:
      "You're responsible for your organisation's IT, probably as an IT manager or admin.",
    imgPath: LicensesAndPperformanceIcon,
  },
  {
    value: 2,
    name: 'Optimising cost',
    description:
      "You look after your business's bottom line, as a CFO or finance manager.",
    imgPath: OptimisingCostIcon,
  },
  {
    value: 3,
    name: 'People, teams and wellbeing',
    description:
      "You're interested in HR and helping your people work effectively.",
    imgPath: PeopleTeamsIcon,
  },
  {
    value: 4,
    name: 'A bit of everything',
    description:
      "Maybe you work in a smaller organisation, or maybe you're just curious.",
    imgPath: EverythingIcon,
  },
];

export enum NotificationTypes {
  TypeOne = 'NU01_UtilisationRenewal',
  TypeTwo = 'NR_NewReport',
  TypeThree = 'NS03_UnlicensedUsers',
  TypeFour = 'NS04_SharedMailboxes',
  TypeFive = 'NU15_UtilisationOverassigned',
  TypeSix = 'NS16_SecureScore',
  TypeSeven = 'NH_HelixIntegrationAlmostReady',
  TypeEight = 'NS17_FailedLoginAttempt',
  TypeNine = 'NI01_InitialIntegrationDataReceived',
  TypeTen = 'NS18_SuspiciousUserAccountActivity',
}
export const notificationsConfig: Map<string, NotificationConf> = new Map([
  [
    'NU01_UtilisationRenewal|High',
    {
      message: '[value] licenses are due for renewal in the next 7 days',
      link: '/insights-and-analytics/utilisation/expiring-soon',
      btnLabel: 'View license data',
    },
  ],
  [
    'NR_NewReport|Information',
    {
      message: 'You have a new report: [value]',
      link: '/reports',
      btnLabel: 'View report',
    },
  ],
  [
    'NS03_UnlicensedUsers|Medium',
    {
      message:
        'Sign-In Enabled: You have [value] unlicensed users whose accounts have sign-in enabled.',
      link: '/insights-and-analytics/assurance/sign-in-enabled',
      btnLabel: 'View Users',
    },
  ],
  [
    'NS04_SharedMailboxes|Medium',
    {
      message: 'Sign-In Enabled: You have [value] shared mailboxes',
      link: '/insights-and-analytics/assurance/shared-mailboxes',
      btnLabel: 'View Mailboxes',
    },
  ],
  [
    'NU15_UtilisationOverassigned|Information',
    {
      message:
        'There are [value] users that you could consider downgrading their assigned licenses.',
      link: '/insights-and-analytics/utilisation/redundant-license',
      btnLabel: 'View Users',
    },
  ],
  [
    'NS16_SecureScore|Information',
    {
      message: 'Your Microsoft Secure Score has increased by [value]%.',
      link: '/insights-and-analytics/assurance/microsoft-secure-score',
      btnLabel: 'Show my Secure Score',
    },
  ],
  [
    'NS16_SecureScore|Medium',
    {
      message: 'Your Microsoft Secure Score has decreased by [value]%.',
      link: '/insights-and-analytics/assurance/microsoft-secure-score',
      btnLabel: 'Show my Secure Score',
    },
  ],
  [
    'NS16_SecureScore|High',
    {
      message: 'Your Microsoft Secure Score has decreased by [value]%.',
      link: '/insights-and-analytics/assurance/microsoft-secure-score',
      btnLabel: 'Show my Secure Score',
    },
  ],
  [
    'NH_HelixIntegrationReady|Information',
    {
      message: 'Enhanced Assurance Insights are now connected',
      link: '/insights-and-analytics/assurance',
      btnLabel: 'View Enhanced Assurance Insights',
    },
  ],
  [
    'NS17_FailedLoginAttempt|Information',
    {
      message: 'You have increased failed login activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/failed-login-data/accounts',
      btnLabel: 'View Activity',
    },
  ],
  [
    'NS17_FailedLoginAttempt|Medium',
    {
      message: 'You have increased failed login activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/failed-login-data/accounts',
      btnLabel: 'View Activity',
    },
  ],
  [
    'NS17_FailedLoginAttempt|High',
    {
      message: 'You have increased failed login activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/failed-login-data/accounts',
      btnLabel: 'View Activity',
    },
  ],
  [
    'NI01_InitialIntegrationDataReceived|Information',
    {
      message: '[value] is now connected to Clevr360',
      link: '/insights-and-analytics/utilisation',
      btnLabel: 'View Insights',
    },
  ],
  [
    'NS18_SuspiciousUserAccountActivity|Information',
    {
      message: 'You have increased suspicious activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/suspicious-activity/activity-data',
      btnLabel: 'View Activity',
    },
  ],
  [
    'NS18_SuspiciousUserAccountActivity|Medium',
    {
      message: 'You have increased suspicious activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/suspicious-activity/activity-data',
      btnLabel: 'View Activity',
    },
  ],
  [
    'NS18_SuspiciousUserAccountActivity|High',
    {
      message: 'You have increased suspicious activity across your connected Microsoft O365 service.',
      link: '/insights-and-analytics/assurance/suspicious-activity/activity-data',
      btnLabel: 'View Activity',
    },
  ],
]);
